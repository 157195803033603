import { DefaultCommon } from '@Commons';
import { version as uuidVersion, validate as uuidValidate } from 'uuid';

class ValidatorUtils {
  private constructor() {}
  static isValidLength(
    value: string | undefined,
    from = DefaultCommon.INPUT_1_LENGTH,
    to = DefaultCommon.INPUT_256_LENGTH,
  ): boolean {
    return !!value && value.length >= from && value.length <= to;
  }

  static isEmptyArray(arr: Array<any> | undefined): boolean {
    return !!arr && Array.isArray(arr) && arr.length === DefaultCommon.VAR_ZERO;
  }

  static isEmptyString(value: string | undefined): boolean {
    return !!value && value?.trim().length === DefaultCommon.VAR_ZERO;
  }

  static isEmptyStringForStaticHosting(value: string | undefined): boolean {
    return value === undefined || value?.trim().length === DefaultCommon.VAR_ZERO;
  }

  static isNotEmptyString(value: string | undefined): boolean {
    return !!value && value?.trim().length !== DefaultCommon.VAR_ZERO;
  }

  static isUUIDv4(value: string): boolean {
    return uuidValidate(value) && uuidVersion(value) === DefaultCommon.VAR_UUID4_VERSION;
  }

  static isMatchRegex(value: string, regex: RegExp): boolean {
    return regex.test(value);
  }

  static isNotMatchRegex(value: string, regex: RegExp): boolean {
    return !regex.test(value);
  }

  static isJSON(value: string): boolean {
    try {
      return !!JSON.parse(value);
    } catch (error) {
      return false;
    }
  }

  static isAllowWithRequired(value: boolean | undefined): boolean {
    return value === undefined || value;
  }

  static isAllowWithOptional(value: boolean | undefined): boolean {
    return value === undefined || value === false ? false : true;
  }

  static isLessThanZero(value: number | undefined): boolean {
    return !!value && value < DefaultCommon.VAR_ZERO;
  }

  static isMoreThanAYear(value: number | undefined): boolean {
    return !!value && value > DefaultCommon.VAR_A_YEAR_IN_SECONDS;
  }
}

export default ValidatorUtils;
