import { Box, Typography } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';
import { TextCommon } from '@Commons';
import { GLOBAL_MUI_SX } from '@Global';
import { Container } from './components';
import StaticHostingProvider from './stores/provider';

const TYPO_H3_STYLE: SystemStyleObject = { textAlign: 'center', py: 2, fontWeight: 'bold' };
const TYPO_SUB_STYLE: SystemStyleObject = { textAlign: 'center', mb: 2, color: 'rgba(0, 0, 0, 0.6)' };

const CodePipelineSetting = (): React.ReactElement => {
  return (
    <StaticHostingProvider>
      <Box sx={GLOBAL_MUI_SX.flexVerticalGrow}>
        <Typography variant="h3" sx={TYPO_H3_STYLE}>
          {TextCommon.TEXT_STATICHOSTING_GENERATION}
        </Typography>
        <Typography variant="subtitle1" sx={TYPO_SUB_STYLE}>
          {TextCommon.TEXT_STATICHOSTING_GENERATION_DESCRIPTION}
        </Typography>
        <Container />
      </Box>
    </StaticHostingProvider>
  );
};

export default CodePipelineSetting;
