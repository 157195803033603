import { IHorizontalItem } from '@Components/HorizontalStepper';
import {
  CodeBuildCompute,
  CodeBuildContainer,
  CodeBuildImage,
  CodePipelineAction,
  ECodeBuildCompute,
  ECodeBuildContainer,
  ECodeBuildImageIdentifier,
  ECodeBuildImagePlatform,
  ECodePipelineActionMode,
} from '@Type';

export const DEFAULT_STEPPERS: IHorizontalItem[] = [
  {
    label: 'AWS Secrets Manager',
    extraText: 'Pre-existing',
    description:
      'Helps you manage, retrieve, and rotate database credentials, API keys, and other secrets throughout their lifecycles',
  },
  {
    label: 'Preparation Resources',
    description: 'Provide other resources to serve CodeBuild and CodePipeline',
  },
  {
    label: 'AWS CodeBuild',
    description:
      'A fully managed continuous integration service that compiles source code, runs tests, and produces ready-to-deploy software packages',
  },
  {
    label: 'AWS CodePipeline',
    description:
      'A fully managed continuous delivery service that helps you automate your release pipelines for fast and reliable application and infrastructure updates',
  },
];

export const DEFAULT_SUB_STEPPERS: IHorizontalItem[] = [
  {
    label: 'CodePipeline',
  },
  {
    label: 'Source Stage',
  },
  {
    label: 'Build Stage',
  },
  {
    label: 'Deploy Stage',
  },
];

/* The AWS CodeBuild Compute type at
  https://docs.aws.amazon.com/codebuild/latest/userguide/build-env-ref-compute-types.html
*/
export const CODEBUILD_COMPUTE_TYPES: CodeBuildCompute = [
  {
    value: ECodeBuildCompute.BUILD_GENERAL1_SMALL,
    desc: 'Use up to 3 GB memory and 2 vCPUs for builds',
  },
  {
    value: ECodeBuildCompute.BUILD_GENERAL1_MEDIUM,
    desc: 'Use up to 7 GB memory and 4 vCPUs for builds',
  },
  {
    value: ECodeBuildCompute.BUILD_GENERAL1_LARGE,
    desc: 'Use up to 15 GB memory and 8 vCPUs for builds',
  },
];

/* The AWS CodeBuild Compute type at
  https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-codebuild-project-environment.html#cfn-codebuild-project-environment-image
*/
export const CODEBUILD_IMAGES: CodeBuildImage = [
  {
    desc: ECodeBuildImagePlatform.AMAZON_LINUX_2,
    value: ECodeBuildImageIdentifier.AMAZON_LINUX_2_STANDARD_4,
  },
  {
    desc: ECodeBuildImagePlatform.AMAZON_LINUX_2023,
    value: ECodeBuildImageIdentifier.AMAZON_LINUX_2_STANDARD_5,
  },
];

/* The AWS CodeBuild Container type at
  https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-codebuild-project-environment.html#cfn-codebuild-project-environment-type
*/

export const CODEBUILD_CONTAINER_TYPES: CodeBuildContainer = [
  {
    value: ECodeBuildContainer.ARM_CONTAINER,
    desc: 'Available only in regions US East (N. Virginia), US East (Ohio), US West (Oregon), EU (Ireland), Asia Pacific (Mumbai), Asia Pacific (Tokyo), Asia Pacific (Sydney), and EU (Frankfurt)',
  },
  {
    value: ECodeBuildContainer.LINUX_CONTAINER,
    desc: 'Compute type build.general1.2xlarge is available only in regions US East (N. Virginia), US East (Ohio), US West (Oregon), Canada (Central), EU (Ireland), EU (London), EU (Frankfurt), Asia Pacific (Tokyo), Asia Pacific (Seoul), Asia Pacific (Singapore), Asia Pacific (Sydney), China (Beijing), and China (Ningxia)',
  },
  {
    value: ECodeBuildContainer.LINUX_GPU_CONTAINER,
    desc: 'Available only in regions US East (N. Virginia), US East (Ohio), US West (Oregon), Canada (Central), EU (Ireland), EU (London), EU (Frankfurt), Asia Pacific (Tokyo), Asia Pacific (Seoul), Asia Pacific (Singapore), Asia Pacific (Sydney) , China (Beijing), and China (Ningxia)',
  },
  {
    value: ECodeBuildContainer.WINDOWS_CONTAINER,
    desc: 'Available only in regions US East (N. Virginia), US East (Ohio), US West (Oregon), and EU (Ireland)',
  },
  {
    value: ECodeBuildContainer.WINDOWS_SERVER_2019_CONTAINER,
    desc: 'Available only in regions US East (N. Virginia), US East (Ohio), US West (Oregon), and EU (Ireland)',
  },
];

/* The AWS CodePipeline Action Deploy at  
  https://docs.aws.amazon.com/codepipeline/latest/userguide/action-reference-CloudFormation.html#action-reference-CloudFormation-config
*/
export const CODEPIPELINE_ACTIONS: CodePipelineAction = [
  {
    value: ECodePipelineActionMode.CHANGE_SET_EXECUTE,
    desc: 'Executes a change set for the resource stack that is based on a set of specified resource updates. With this action, AWS CloudFormation starts to alter the stack',
  },
  {
    value: ECodePipelineActionMode.CHANGE_SET_REPLACE,
    desc: "Creates the change set, if it doesn't exist, based on the stack name and template that you submit. If the change set exists, AWS CloudFormation deletes it, and then creates a new one",
  },
  {
    value: ECodePipelineActionMode.CREATE_UPDATE,
    desc: "Creates the stack if it doesn't exist. If the stack exists, AWS CloudFormation updates the stack. Use this action to update existing stacks",
  },
  {
    value: ECodePipelineActionMode.DELETE_ONLY,
    desc: "Deletes a stack. If you specify a stack that doesn't exist, the action is completed successfully without deleting a stack",
  },
  {
    value: ECodePipelineActionMode.REPLACE_ON_FAILURE,
    desc: "Creates a stack, if it doesn't exist. If the stack exists and is in a failed state, AWS CloudFormation deletes the stack, and then creates a new stack. If the stack isn't in a failed state, AWS CloudFormation updates it",
  },
];
