import { SystemStyleObject } from '@mui/system';
import { PopoverOrigin, SnackbarOrigin } from '@mui/material';
import { ThemeOptions } from '@mui/material';

export const themeRoot: ThemeOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
};

interface IMuiSx {
  w100: SystemStyleObject;
  positionFixed: SystemStyleObject;
  borderBox: SystemStyleObject;
  fw500: SystemStyleObject;
  textCenter: SystemStyleObject;
  flexVerticalGrow: SystemStyleObject;
  flexHorizontalGrow: SystemStyleObject;
  anchorOriginTopRight: PopoverOrigin | SnackbarOrigin;
  anchorOriginBottomRight: PopoverOrigin | SnackbarOrigin;
  anchorOriginTopCenter: SnackbarOrigin | SnackbarOrigin;
  flexHorizontalGrowContentCenter: SystemStyleObject;
}

export const GLOBAL_MUI_SX: IMuiSx = {
  w100: { width: '100%' },
  positionFixed: { position: 'fixed' },
  borderBox: { boxSizing: 'border-box' },
  fw500: { fontWeight: 500 },
  textCenter: { textAlign: 'center' },
  anchorOriginTopRight: { vertical: 'top', horizontal: 'right' },
  anchorOriginBottomRight: { vertical: 'bottom', horizontal: 'right' },
  anchorOriginTopCenter: { vertical: 'top', horizontal: 'center' },
  flexVerticalGrow: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
  flexHorizontalGrow: { display: 'flex', flexDirection: 'row', flexGrow: 1 },
  flexHorizontalGrowContentCenter: { display: 'flex', flexGrow: 1, justifyContent: 'center' },
};
