import { useEffect } from 'react';
import { Grid } from 'nxg-ui-wrapper';

import { TextCommon } from '@Commons';
import { InputControl } from '@Components';
import { ICodePipelineSettingData } from '@Type';
import { ValidatorUtils } from '@Utils';
import { GLOBAL_MUI_SX } from '@Global';

import { ActionHandler } from '../stores/action';
import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { CodePipelineTest } from '_Test';

interface IStageBuildProps {
  onDenyNext: (value: boolean) => void;
}

const StageBuild = ({ onDenyNext }: IStageBuildProps): React.ReactElement => {
  const { codePipeline } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();

  const { buildStage } = codePipeline;

  useEffect(() => {
    const nameError = ValidatorUtils.isAllowWithOptional(buildStage.name.isError);
    const actionNameError = ValidatorUtils.isAllowWithOptional(buildStage.actionName.isError);
    const isNext = nameError || actionNameError;
    onDenyNext(isNext);
  }, [buildStage.name, buildStage.actionName]);

  return (
    <Grid container flexGrow={1} spacing={3} component="form">
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_BUILD_NAME}
          control={codePipeline.buildStage.name}
          label={TextCommon.TEXT_STAGE_NAME}
          helperText={TextCommon.TEXT_HELPER_STAGE_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageBuildName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_BUILD_ACTION_NAME}
          control={codePipeline.buildStage.actionName}
          label={TextCommon.TEXT_ACTION_NAME}
          helperText={TextCommon.TEXT_HELPER_ACTION_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageBuildActionName(dispatch, value)}
        />
      </Grid>
    </Grid>
  );
};

export default StageBuild;
