import * as cfn from 'yaml-cfn';
import { format } from 'json-string-formatter';
import { RegexCommon, DefaultCommon } from '@Commons';

class TransformUtils {
  static replaceTabToSpace(input: string): string {
    return input.replace(RegexCommon.CHARACTER_TAB, DefaultCommon.VAR_CHARACTER_SPACE);
  }
  static parseYamlToJsonObject(yaml: string): object {
    return cfn.yamlParse(yaml);
  }

  static parseYamlToJsonStr(yaml: string): string {
    const parseToStr = JSON.stringify(TransformUtils.parseYamlToJsonObject(yaml));
    return TransformUtils.replaceTabToSpace(format(parseToStr));
  }

  static parseJsonStrToYaml(json: string): string {
    return cfn.yamlParse(json);
  }
}

export default TransformUtils;
