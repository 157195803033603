import { Outlet } from 'react-router-dom';

import { Box, Container } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';

import Header from '@StaticPages/Header';
import Footer from '@StaticPages/Footer';
import { GLOBAL_MUI_SX } from '@Global';

const BOX_STYLE: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  backgroundColor: '#f2f2f2',
};

const MainLayout = (): React.ReactElement => {
  return (
    <>
      <Box sx={BOX_STYLE}>
        <Box>
          <Header />
        </Box>
        <Box sx={GLOBAL_MUI_SX.flexHorizontalGrow}>
          <Container fixed maxWidth="md" sx={GLOBAL_MUI_SX.flexVerticalGrow}>
            <Outlet />
          </Container>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
