import { AlertColor } from '@mui/material/Alert';

import { DefaultCommon } from '@Commons';
import { AppContextType, AppDispatchType, EAppAction, TAppDispatch } from './action';

export const APP_INITIAL: AppContextType = {
  notify: {
    isOpen: false,
    message: DefaultCommon.VAR_EMPTY_STRING,
    type: 'info',
  },
};

const appReducer = (prevState: AppContextType, dispatch: AppDispatchType<TAppDispatch>): AppContextType => {
  switch (dispatch.action) {
    case EAppAction.SET_NOTIFY_OPEN:
      return {
        ...prevState,
        notify: {
          ...prevState.notify,
          isOpen: true,
          message: dispatch?.payload?.message as string,
          type: dispatch?.payload?.type as AlertColor,
        },
      };
    case EAppAction.SET_NOTIFY_CLOSE:
      return {
        ...prevState,
        notify: {
          ...prevState.notify,
          isOpen: false,
          message: '',
          type: 'info',
        },
      };
    default:
      return prevState;
  }
};

export default appReducer;
