import { useEffect } from 'react';
import { Grid } from 'nxg-ui-wrapper';

import { DefaultCommon, TextCommon } from '@Commons';
import { InputControl } from '@Components';
import { ICodePipelineSettingData } from '@Type';
import { ValidatorUtils } from '@Utils';
import { ActionHandler } from '../stores/action';
import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { GLOBAL_MUI_SX } from '@Global';
import { CodePipelineTest } from '_Test';

interface ICodePipelineDescProps {
  onDenyNext: (value: boolean) => void;
}

const CodePipelineDesc = ({ onDenyNext }: ICodePipelineDescProps): React.ReactElement => {
  const { codePipeline } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();

  useEffect(() => {
    const nameError = ValidatorUtils.isAllowWithOptional(codePipeline.name.isError);
    onDenyNext(nameError);
  }, [codePipeline.name]);

  return (
    <Grid container flexGrow={1} spacing={3} component="form">
      <Grid item sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter} xs={12}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_NAME}
          control={codePipeline.name}
          label={TextCommon.TEXT_CODEPIPELINE_NAME}
          helperText={[TextCommon.TEXT_HELPER_CODEPIPELINE_NAME, TextCommon.TEXT_HELPER_AUTO_INITIAL].join(
            DefaultCommon.VAR_CHARACTER_DOTS + DefaultCommon.VAR_CHARACTER_SPACE,
          )}
          onChangeValue={(value: string): void => ActionHandler.setCodePipelineName(dispatch, value)}
        />
      </Grid>
    </Grid>
  );
};

export default CodePipelineDesc;
