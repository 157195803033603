import { MsgCommon } from '@Commons';
/* eslint-disable no-case-declarations */
import { DefaultCommon, TextCommon } from '@Commons';
import {
  ECodeBuildCompute,
  ECodeBuildContainer,
  ECodeBuildImageIdentifier,
  ECodePipelineActionMode,
  IBuildStage,
  ICodeBuild,
  ICodePipeline,
  ICodePipelineSettingData,
  IDeployStage,
  IFieldInput,
  ISecretsManager,
  ISourceStage,
  IValidStep,
} from '@Type';
import { EAction, ECodepipelineAction } from './action';

export const NONE_ERROR = {
  isError: false,
  errorMsg: TextCommon.TEXT_EMPTY_STRING,
};

export const INITIAL_SETTING_DATA: ICodePipelineSettingData = {
  isValidSteps: {},
  secretsManager: {
    name: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    tokenVersion: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
  },
  codeBuild: {
    roleName: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    bucketName: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    deletionPolicy: {
      value: false,
    },
    name: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    buildSpec: {
      value: DefaultCommon.CONST_BUILD_SPEC,
      isError: false,
    },
    computeType: {
      value: ECodeBuildCompute.BUILD_GENERAL1_SMALL.toString(),
    },
    containerType: {
      value: ECodeBuildContainer.LINUX_CONTAINER,
    },
    image: {
      value: ECodeBuildImageIdentifier.AMAZON_LINUX_2_STANDARD_5,
    },
  },
  codePipeline: {
    name: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    roleName: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    bucketName: {
      value: DefaultCommon.VAR_EMPTY_STRING,
    },
    deletionPolicy: {
      value: false,
    },
    sourceStage: {
      name: {
        value: DefaultCommon.CONST_SOURCE,
        isError: false,
      },
      actionName: {
        value: DefaultCommon.CONST_FETCH_SOURCE,
        isError: false,
      },
      githubBranch: {
        value: DefaultCommon.VAR_EMPTY_STRING,
      },
      githubOwner: {
        value: DefaultCommon.VAR_EMPTY_STRING,
      },
      githubRepo: {
        value: DefaultCommon.VAR_EMPTY_STRING,
      },
    },
    buildStage: {
      name: {
        value: DefaultCommon.CONST_BUILD,
        isError: false,
      },
      actionName: {
        value: DefaultCommon.CONST_BUILD_SERVERLESS,
        isError: false,
      },
    },
    deployStage: {
      name: {
        value: DefaultCommon.CONST_DEPLOY,
        isError: false,
      },
      createChangeSetActionName: {
        value: DefaultCommon.CONST_CREATE_CHANGE_SET,
        isError: false,
      },
      applyChangeSetActionName: {
        value: DefaultCommon.CONST_APPLY_CHANGE_SET,
        isError: false,
      },
      createChangeSetActionMode: {
        value: ECodePipelineActionMode.CHANGE_SET_REPLACE,
      },
      applyChangeSetActionMode: {
        value: ECodePipelineActionMode.CHANGE_SET_EXECUTE,
      },
      stackName: {
        value: DefaultCommon.VAR_EMPTY_STRING,
      },
      templatePath: {
        value: DefaultCommon.CONST_STACK_TEMPLATE,
        isError: false,
      },
      parameterOverrides: {
        value: {},
      },
    },
  },
};

const _mappingSecretsManager = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof ISecretsManager,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    secretsManager: {
      ...prevState.secretsManager,
      [property]: { ...(action.payload as IFieldInput<T>) },
    },
  };
};

const _mappingCodeBuild = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof ICodeBuild,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    codeBuild: {
      ...prevState.codeBuild,
      [property]: { ...(action.payload as IFieldInput<T>) },
    },
  };
};

const _mappingCodePipeline = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof ICodePipeline,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    codePipeline: {
      ...prevState.codePipeline,
      [property]: { ...(action.payload as IFieldInput<T>) },
    },
  };
};

const _mappingCodePipelineStageSource = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof ISourceStage,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    codePipeline: {
      ...prevState.codePipeline,
      sourceStage: {
        ...prevState.codePipeline.sourceStage,
        [property]: { ...(action.payload as IFieldInput<T>) },
      },
    },
  };
};

const _mappingCodePipelineStageBuild = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof IBuildStage,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    codePipeline: {
      ...prevState.codePipeline,
      buildStage: {
        ...prevState.codePipeline.buildStage,
        [property]: { ...(action.payload as IFieldInput<T>) },
      },
    },
  };
};

const _mappingCodePipelineStageDeploy = <T>(
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<T>,
  property: keyof IDeployStage,
): ICodePipelineSettingData => {
  return {
    ...prevState,
    codePipeline: {
      ...prevState.codePipeline,
      deployStage: {
        ...prevState.codePipeline.deployStage,
        [property]: { ...(action.payload as IFieldInput<T>) },
      },
    },
  };
};

const codePipelineReducer = (
  prevState: ICodePipelineSettingData,
  action: ECodepipelineAction<any>,
): ICodePipelineSettingData => {
  switch (action.type) {
    case EAction.SET_SECRET_MANAGER_NAME:
      return _mappingSecretsManager<string>(prevState, action, 'name');

    case EAction.SET_SECRET_MANAGER_TOKEN_VERSION:
      return _mappingSecretsManager<string>(prevState, action, 'tokenVersion');

    case EAction.SET_CODEBUILD_ROLE_NAME:
      return _mappingCodeBuild<string>(prevState, action, 'roleName');

    case EAction.SET_CODEBUILD_BUCKET_NAME:
      return _mappingCodeBuild<string>(prevState, action, 'bucketName');

    case EAction.SET_CODEBUILD_DELETE_POLICY:
      return _mappingCodeBuild<boolean>(prevState, action, 'deletionPolicy');

    case EAction.SET_CODEBUILD_NAME:
      return _mappingCodeBuild<string>(prevState, action, 'name');

    case EAction.SET_CODEBUILD_BUILD_SPEC:
      return _mappingCodeBuild<string>(prevState, action, 'buildSpec');

    case EAction.SET_CODEBUILD_COMPUTE_TYPE:
      return _mappingCodeBuild<string>(prevState, action, 'computeType');

    case EAction.SET_CODEBUILD_IMAGE_TYPE:
      return _mappingCodeBuild<string>(prevState, action, 'image');

    case EAction.SET_CODEBUILD_CONTAINER_TYPE:
      return _mappingCodeBuild<string>(prevState, action, 'containerType');

    case EAction.SET_CODEPIPELINE_NAME:
      return _mappingCodePipeline<string>(prevState, action, 'name');

    case EAction.SET_CODEPIPELINE_ROLE_NAME:
      return _mappingCodePipeline<string>(prevState, action, 'roleName');

    case EAction.SET_CODEPIPELINE_BUCKET_NAME:
      return _mappingCodePipeline<string>(prevState, action, 'bucketName');

    case EAction.SET_CODEPIPELINE_DELETE_POLICY:
      return _mappingCodePipeline<string>(prevState, action, 'deletionPolicy');

    case EAction.SET_CODEPIPELINE_SOURCE_NAME:
      return _mappingCodePipelineStageSource<string>(prevState, action, 'name');

    case EAction.SET_CODEPIPELINE_SOURCE_ACTION_NAME:
      return _mappingCodePipelineStageSource<string>(prevState, action, 'actionName');

    case EAction.SET_CODEPIPELINE_SOURCE_GITHUB_REPO:
      return _mappingCodePipelineStageSource<string>(prevState, action, 'githubRepo');

    case EAction.SET_CODEPIPELINE_SOURCE_GITHUB_OWNER:
      return _mappingCodePipelineStageSource<string>(prevState, action, 'githubOwner');

    case EAction.SET_CODEPIPELINE_SOURCE_GITHUB_BRANCH:
      return _mappingCodePipelineStageSource<string>(prevState, action, 'githubBranch');

    case EAction.SET_CODEPIPELINE_BUILD_NAME:
      return _mappingCodePipelineStageBuild<string>(prevState, action, 'name');

    case EAction.SET_CODEPIPELINE_BUILD_ACTION_NAME:
      return _mappingCodePipelineStageBuild<string>(prevState, action, 'actionName');

    case EAction.SET_CODEPIPELINE_DEPLOY_NAME:
      return _mappingCodePipelineStageDeploy<string>(prevState, action, 'name');

    case EAction.SET_CODEPIPELINE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME:
      return _mappingCodePipelineStageDeploy<string>(prevState, action, 'createChangeSetActionName');

    case EAction.SET_CODEPIPELINE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME:
      return _mappingCodePipelineStageDeploy<string>(prevState, action, 'applyChangeSetActionName');

    case EAction.SET_CODEPIPELINE_DEPLOY_STACK_NAME:
      return _mappingCodePipelineStageDeploy<string>(prevState, action, 'stackName');

    case EAction.SET_CODEPIPELINE_DEPLOY_TEMP_PATH:
      return _mappingCodePipelineStageDeploy<string>(prevState, action, 'templatePath');

    case EAction.SET_CODEPIPELINE_DEPLOY_PARAMETER:
      return _mappingCodePipelineStageDeploy<object>(prevState, action, 'parameterOverrides');

    case EAction.SET_VALID_STEPS:
      return {
        ...prevState,
        isValidSteps: {
          ...prevState.isValidSteps,
          ...(action.payload as IValidStep),
        },
      };
    case EAction.RESET:
      return INITIAL_SETTING_DATA;

    default:
      throw Error(MsgCommon.NOT_FOUND_ACTION);
  }
};

export default codePipelineReducer;
