import { useMemo } from 'react';
import { Grid, ListItemText, MenuItem, Select, Typography } from 'nxg-ui-wrapper';
import { SelectChangeEvent } from '@mui/material/Select';

import { DefaultCommon } from '@Commons';

interface SelectWithExtendProps<SelectWithExtendItem> {
  label: string;
  required?: boolean;
  value: string | number;
  items: SelectWithExtendItem[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

export interface SelectWithExtendItem {
  value: string | number;
  desc?: string;
}

const SelectWithExtend = (props: SelectWithExtendProps<SelectWithExtendItem>): React.ReactElement => {
  const { label, value, items, required, onChange } = props;
  const labelSelect = useMemo(() => {
    if (required) {
      return label + DefaultCommon.VAR_CHARACTER_SPACE + DefaultCommon.VAR_CHARACTER_ASTERISK;
    }
    return label;
  }, [required]);

  return (
    <Select
      data-cy={props['data-cy']}
      value={value}
      onChange={onChange}
      label={labelSelect}
      MenuProps={{ sx: { maxWidth: 'fit-content' } }}
    >
      {items.map((item: SelectWithExtendItem): React.ReactElement => {
        const Primary = (
          <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>
            {item.value}
          </Typography>
        );

        return (
          <MenuItem key={item.value} value={item.value} sx={{ maxWidth: '100%' }}>
            <Grid container spacing="2">
              <Grid item xs={12}>
                <ListItemText primary={Primary} />
              </Grid>
              {item.desc && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="span" sx={{ color: '#1976d2cc', whiteSpace: 'pre-wrap' }}>
                    {item.desc}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectWithExtend;
