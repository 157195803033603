/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from 'nxg-ui-wrapper';
import { GLOBAL_MUI_SX } from '@Global';

export interface IHorizontalItem extends IHorizontalItemWithoutExtra {
  extraText?: string;
  optional?: boolean;
  description?: string;
}
export interface IHorizontalItemWithoutExtra {
  label: string;
  completed?: boolean;
}

export interface IHorizontalStepperProps {
  steps: IHorizontalItemWithoutExtra[];
  children?: React.ReactNode;
  activeStep: number;
}

const HorizontalStepper = ({ steps, children, activeStep }: IHorizontalStepperProps): React.ReactElement => {
  return (
    <Box sx={GLOBAL_MUI_SX.w100}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step: IHorizontalItem) => {
          return (
            <Step key={step.label} completed={step?.completed}>
              <StepLabel
                optional={step.optional}
                sx={theme => ({
                  color: theme.palette.primary.main,
                })}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {children}
    </Box>
  );
};

export default HorizontalStepper;
