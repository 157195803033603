/* eslint-disable no-useless-escape */
const CODEPIPELINE_CFN_TEMPLATE = `AWSTemplateFormatVersion: 2010-09-09
Description: The CloudFormation template to create a CodePipeline.
Parameters:
  AimsCSSecret:
    Description: The Secrets Manager name
    Type: String
    Default: "{secretsManager.name.value}"
  GitHubOwner:
    Type: String
    Description: The GitHub Owner name
    Default: "{codePipeline.sourceStage.githubOwner.value}"
  GitHubRepo:
    Type: String
    Description: The GitHub Repository name
    Default: "{codePipeline.sourceStage.githubRepo.value}"
  GitHubBranch:
    Type: String
    Description: The GitHub Branch name
    Default: "{codePipeline.sourceStage.githubBranch.value}"
  GitHubTokenVersion:
    Type: String
    Description: The version of GITHUB_TOKEN in Secret Manager
    Default: "{secretsManager.tokenVersion.value}"

Conditions:
  # CODE_BUILD
  IsCodebuildRoleNameValid: !Not [ !Equals ["{codeBuild.roleName.value}", "" ]]
  IsCodebuildBucketNameValid: !Not [ !Equals ["{codeBuild.bucketName.value}", "" ]]
  IsCodebuildNameValid: !Not [ !Equals ["{codeBuild.name.value}", "" ]]
  # CODE_PIPELINE
  IsCodepipelineRoleNameValid: !Not [ !Equals ["{codePipeline.roleName.value}", "" ]]
  IsCodepipelineBucketNameValid: !Not [ !Equals ["{codePipeline.bucketName.value}", "" ]]
  IsCodepipelineNameValid: !Not [ !Equals ["{codePipeline.name.value}", "" ]]
  # TOKEN_VERSION
  IsTokenVersionValid: !Not [ !Equals [ !Ref GitHubTokenVersion, "" ]]

Resources:
  AimsCSCodePipelineRole:
    Type: AWS::IAM::Role
    Properties:
      RoleName: 
        !If [ IsCodepipelineRoleNameValid, "{codePipeline.roleName.value}", !Ref AWS::NoValue ]
      AssumeRolePolicyDocument:
        Statement:
          - Effect: Allow
            Action:
              - "sts:AssumeRole"
            Principal:
              Service:
                - codepipeline.amazonaws.com
                - codebuild.amazonaws.com
                - cloudformation.amazonaws.com
        Version: 2012-10-17
      ManagedPolicyArns:
        - arn:aws:iam::aws:policy/AWSCodePipeline_FullAccess
        - arn:aws:iam::aws:policy/AmazonS3FullAccess
        - arn:aws:iam::aws:policy/AWSCodeBuildAdminAccess
        - arn:aws:iam::aws:policy/AWSCloudFormationFullAccess
        - arn:aws:iam::aws:policy/IAMFullAccess
        - arn:aws:iam::aws:policy/CloudWatchFullAccess
        - arn:aws:iam::aws:policy/AWSLambda_FullAccess
        - arn:aws:iam::aws:policy/AmazonAPIGatewayAdministrator  
      Path: /

  AimsCSCodeBuildRole:
    Type: AWS::IAM::Role
    Properties:
      RoleName: 
        !If [ IsCodebuildRoleNameValid, "{codeBuild.roleName.value}", !Ref AWS::NoValue ]
      AssumeRolePolicyDocument:
        Statement:
          - Effect: Allow
            Action:
              - "sts:AssumeRole"
            Principal:
              Service:
                - codebuild.amazonaws.com
        Version: 2012-10-17
      ManagedPolicyArns:
        - arn:aws:iam::aws:policy/AmazonS3FullAccess
        - arn:aws:iam::aws:policy/AWSCodeBuildAdminAccess
        - arn:aws:iam::aws:policy/IAMFullAccess
        - arn:aws:iam::aws:policy/CloudWatchFullAccess
        - arn:aws:iam::aws:policy/AWSLambda_FullAccess
        - arn:aws:iam::aws:policy/AmazonAPIGatewayAdministrator
      Path: /

  # S3
  AimsCSCodeBuildArtifactsBucket:
    Type: AWS::S3::Bucket
    Properties:
      BucketName: 
        !If [ IsCodebuildBucketNameValid, "{codeBuild.bucketName.value}", !Ref AWS::NoValue ]
    DeletionPolicy: "{codeBuild.deletionPolicy}"
  AimsCSPipelineArtifactsBucket:
    Type: AWS::S3::Bucket
    Properties:
      BucketName: 
        !If [ IsCodepipelineBucketNameValid, "{codePipeline.bucketName.value}", !Ref AWS::NoValue ]
    DeletionPolicy: "{codePipeline.deletionPolicy}"

  # CodeBuild
  AimsCSCodeBuildProject:
    DependsOn:
      - AimsCSCodeBuildArtifactsBucket
    Type: AWS::CodeBuild::Project
    Properties:
      Name: 
        !If [ IsCodebuildNameValid, "{codeBuild.name.value}", !Ref AWS::NoValue ]
      Artifacts:
        Type: CODEPIPELINE
      Source:
        Type: CODEPIPELINE
        BuildSpec: "{codeBuild.buildSpec.value}"
      Environment:
        ComputeType: "{codeBuild.computeType.value}"
        Image: "{codeBuild.image.value}"
        Type: "{codeBuild.containerType.value}"
        EnvironmentVariables:
          - Name: GITHUB_BRANCH
            Type: PLAINTEXT
            Value: !Sub "\${{GitHubBranch}}"
          - Name: GITHUB_REPO
            Type: PLAINTEXT
            Value: !Sub "\${{GitHubRepo}}"
          - Name: AimsCSCodeBuildArtifactsBucket
            Type: PLAINTEXT
            Value: !Sub "\${{AimsCSCodeBuildArtifactsBucket}}"
      ServiceRole: !Sub "\${{AimsCSCodeBuildRole}}"

  AimsCSCodePipeline:
    DependsOn:
      - AimsCSCodeBuildProject
      - AimsCSPipelineArtifactsBucket
    Type: AWS::CodePipeline::Pipeline
    Properties:
      Name:
        !If [ IsCodepipelineNameValid, "{codePipeline.name.value}", !Ref AWS::NoValue ]
      ArtifactStore:
        Type: S3
        Location: !Ref AimsCSPipelineArtifactsBucket
      RestartExecutionOnUpdate: true
      RoleArn: !GetAtt AimsCSCodePipelineRole.Arn
      Stages:
        - Name: "{codePipeline.sourceStage.name.value}"
          Actions:
            - Name: "{codePipeline.sourceStage.actionName.value}"
              ActionTypeId:
                Category: Source
                Owner: ThirdParty
                Version: "1"
                Provider: GitHub
              OutputArtifacts:
                - Name: SourceCode
              Configuration:
                Owner: !Ref GitHubOwner
                Repo: !Ref GitHubRepo
                Branch: !Ref GitHubBranch
                PollForSourceChanges: false
                OAuthToken: 
                  !Join
                    - ''
                    - - '{{{{'
                      - 'resolve:secretsmanager:'
                      - !Sub '\${{AimsCSSecret}}'
                      - ':SecretString:GITHUB_TOKEN'
                      - !If [ IsTokenVersionValid, !Sub '::\${{GitHubTokenVersion}}', '' ]
                      - '}}}}'
              RunOrder: 1
        - Name: "{codePipeline.buildStage.name.value}"
          Actions:
            - Name: "{codePipeline.buildStage.actionName.value}"
              Namespace: "Serverless"
              ActionTypeId:
                Category: Build
                Owner: AWS
                Provider: CodeBuild
                Version: "1"
              Configuration:
                ProjectName: !Ref AimsCSCodeBuildProject
              InputArtifacts:
                - Name: SourceCode
              OutputArtifacts:
                - Name: BuildCode
              RunOrder: 1
        - Name: "{codePipeline.deployStage.name.value}"
          Actions:
            - Name: "{codePipeline.deployStage.createChangeSetActionName.value}"
              ActionTypeId:
                Category: Deploy
                Owner: AWS
                Provider: CloudFormation
                Version: "1"
              InputArtifacts:
                - Name: BuildCode
              Configuration:
                ActionMode: {codePipeline.deployStage.createChangeSetActionMode.value}
                Capabilities: CAPABILITY_AUTO_EXPAND,CAPABILITY_NAMED_IAM,CAPABILITY_IAM
                RoleArn: !GetAtt AimsCSCodePipelineRole.Arn
                StackName: !Sub "{codePipeline.deployStage.stackName.value}"
                ChangeSetName: !Sub "{codePipeline.deployStage.stackName.value}-ChangeSet"
                TemplatePath: !Sub "BuildCode::{codePipeline.deployStage.templatePath.value}"
                ParameterOverrides: !Sub '{codePipeline.deployStage.parameterOverrides}'
              RunOrder: 1
            - Name: "{codePipeline.deployStage.applyChangeSetActionName.value}"
              ActionTypeId:
                Category: Deploy
                Owner: AWS
                Provider: CloudFormation
                Version: "1"
              InputArtifacts:
                - Name: BuildCode
              Configuration:
                ActionMode: {codePipeline.deployStage.applyChangeSetActionMode.value}
                Capabilities: CAPABILITY_AUTO_EXPAND,CAPABILITY_NAMED_IAM,CAPABILITY_IAM
                RoleArn: !GetAtt AimsCSCodePipelineRole.Arn
                StackName: !Sub "{codePipeline.deployStage.stackName.value}"
                ChangeSetName: !Sub "{codePipeline.deployStage.stackName.value}-ChangeSet"
                TemplatePath: !Sub "BuildCode::{codePipeline.deployStage.templatePath.value}"
              RunOrder: 2
  GithubWebhook:
    Type: "AWS::CodePipeline::Webhook"
    Properties:
      Authentication: GITHUB_HMAC
      AuthenticationConfiguration:
        SecretToken:
          !Join
            - ''
            - - '{{{{'
              - 'resolve:secretsmanager:'
              - !Sub '\${{AimsCSSecret}}'
              - ':SecretString:GITHUB_TOKEN'
              - !If [ IsTokenVersionValid, !Sub '::\${{GitHubTokenVersion}}', '' ]
              - '}}}}' 
      RegisterWithThirdParty: true
      Filters:
        - JsonPath: "$.ref"
          MatchEquals: refs/heads/{{Branch}}
      TargetPipeline: !Ref AimsCSCodePipeline
      TargetAction: "{codePipeline.sourceStage.actionName.value}"
      TargetPipelineVersion: !GetAtt AimsCSCodePipeline.Version
`;

export default CODEPIPELINE_CFN_TEMPLATE;
