import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { SystemStyleObject } from '@mui/system';

import { Menu, Typography, Toolbar, MenuItem, AppBar, IconButton, Box } from 'nxg-ui-wrapper';

import { IMG_LOGO } from '@Assets';
import { DefaultCommon, TextCommon } from '@Commons';
import { GLOBAL_MUI_SX } from '@Global';

export interface IMenuRoute {
  path: string;
  text: string;
}
const TYPO_STYLE: SystemStyleObject = {
  ml: 1,
  display: 'flex',
  alignItems: 'center',
};

const Header = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const routers: IMenuRoute[] = [
    { path: DefaultCommon.CONST_CODE_PIPELINE_PATH, text: TextCommon.TEXT_CODEPIPELINE_GENERATION },
    { path: DefaultCommon.CONST_STATIC_HOSTING_PATH, text: TextCommon.TEXT_STATIC_HOSTING_GENERATION },
  ];

  const handelLinkTo = (path: string): void => {
    const hasPath = routers.some(el => el.path === path);
    if (hasPath) {
      navigate(path);
    } else {
      navigate(DefaultCommon.CONST_CODE_PIPELINE_PATH);
    }
    handleClose();
  };

  const renderMenuItem = (): JSX.Element[] => {
    return routers.map((route: IMenuRoute) => {
      return <MenuItem onClick={(): void => handelLinkTo(route.path)}> {route.text} </MenuItem>;
    });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenu}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={GLOBAL_MUI_SX.anchorOriginTopRight}
          transformOrigin={GLOBAL_MUI_SX.anchorOriginTopRight}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {renderMenuItem()}
        </Menu>
        <Box sx={GLOBAL_MUI_SX.flexHorizontalGrow}>
          <img height="36" src={IMG_LOGO} alt={TextCommon.TEXT_ACCRETECH} />
          <Typography component="div" sx={TYPO_STYLE}>
            {TextCommon.TEXT_TOKYO_SEIMITSU}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
