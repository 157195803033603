import { useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';

import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { DefaultCommon, TextCommon } from '@Commons';
import { useStepperControl } from '@Hooks';
import { HorizontalStepper } from '@Components';
import { GLOBAL_MUI_SX } from '@Global';
import { DEFAULT_STEPPERS, DEFAULT_SUB_STEPPERS } from '../common';
import PreparationResource from './PreparationResource';
import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { ActionHandler } from '../stores/action';
import { CodeBuild, CodePipeline, SecretsManager, PreviewDialog } from '.';
import { CodePipelineTest } from '_Test';

const MUI_STYLE: SystemStyleObject = {
  ...GLOBAL_MUI_SX.flexVerticalGrow,
  border: '-moz-initial',
  borderRadius: 2,
  backgroundColor: '#fff',
};

const GRID_BUTTON_STYLE: SystemStyleObject = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
};

const BUTTON_STYLE: SystemStyleObject = { mr: 2 };

const StepperControl = (): React.ReactElement => {
  const { steps, activeStep, action } = useStepperControl({ data: DEFAULT_STEPPERS });
  const [denyNext, setDenyNext] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const setting = useCodePipeline();
  const dispatch = useCodePipelineDispatch();

  const handleDenyNext = (value: boolean): void => {
    setDenyNext(value);
  };

  const handlePreview = (): void => {
    setOpenDialog(true);
  };

  const handleReset = (): void => {
    action.handleResetAction();
    ActionHandler.reset(dispatch);
  };

  const handleNext = (): void => {
    action.handleNextAction();
  };

  const handleDialogClosed = (): void => {
    setOpenDialog(false);
  };

  const renderCase = (): React.ReactElement | undefined => {
    const stepCases: React.ReactElement[] = [
      <SecretsManager onDenyNext={handleDenyNext} />,
      <PreparationResource onDenyNext={handleDenyNext} />,
      <CodeBuild onDenyNext={handleDenyNext} />,
      <CodePipeline />,
    ];
    return stepCases[activeStep];
  };

  const isFinish = useMemo(() => {
    const keys = Object.keys(setting?.isValidSteps || DefaultCommon.VAR_EMPTY_OBJECT);

    const isValidSteps = (): boolean => {
      return keys.every((key: string) => {
        return setting?.isValidSteps[key];
      });
    };

    const isValid = keys.length === DEFAULT_SUB_STEPPERS.length && isValidSteps();

    if (action.isFinish !== isValid) {
      action.handleFinishAction(false);
    }

    return isValid;
  }, [setting?.isValidSteps]);

  return (
    <>
      <Box py={3}>
        <HorizontalStepper steps={steps} activeStep={activeStep} />
      </Box>
      <Box sx={MUI_STYLE}>
        <Box py={3} px={3} flexGrow={1}>
          <Grid container display="flex" flexDirection="column">
            <Typography variant="h5" sx={{ ...GLOBAL_MUI_SX.textCenter, fontWeight: 'bold' }}>
              {steps[activeStep].label}
            </Typography>
            {steps[activeStep].extraText && (
              <Typography variant="subtitle1" sx={GLOBAL_MUI_SX.textCenter}>
                {steps[activeStep].extraText}
              </Typography>
            )}
            {steps[activeStep].description && (
              <Typography variant="subtitle2" component="i" sx={{ ...GLOBAL_MUI_SX.textCenter, mt: 1 }}>
                {steps[activeStep].description}
              </Typography>
            )}
            <div>{renderCase()}</div>
          </Grid>
        </Box>
      </Box>
      <Box py={2}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              data-cy={CodePipelineTest.CONTROL_PREV}
              variant="contained"
              startIcon={<SkipPreviousIcon />}
              disabled={action.isFirst}
              onClick={action.handleBackAction}
            >
              {TextCommon.TEXT_BTN_BACK}
            </Button>
          </Grid>
          <Grid item xs={6} sx={GRID_BUTTON_STYLE}>
            {!action.isLast && (
              <Button
                data-cy={CodePipelineTest.CONTROL_NEXT}
                endIcon={<SkipNextIcon />}
                variant="contained"
                sx={BUTTON_STYLE}
                disabled={action.isLast || denyNext}
                onClick={handleNext}
              >
                {TextCommon.TEXT_BTN_NEXT}
              </Button>
            )}
            {action.isLast && (!action.isFinish || !isFinish) && (
              <Button
                data-cy={CodePipelineTest.CONTROL_FINISH}
                endIcon={<CheckCircleOutlineIcon />}
                variant="contained"
                sx={BUTTON_STYLE}
                disabled={!action.isLast || !isFinish}
                onClick={(): void => action.handleFinishAction(true)}
              >
                {TextCommon.TEXT_BTN_FINISH}
              </Button>
            )}
            {action.isLast && action.isFinish && isFinish && (
              <>
                <Button
                  data-cy={CodePipelineTest.CONTROL_RESET}
                  color="error"
                  endIcon={<RestartAltIcon />}
                  variant="contained"
                  sx={BUTTON_STYLE}
                  onClick={handleReset}
                >
                  {TextCommon.TEXT_BTN_RESET}
                </Button>
                <Button
                  data-cy={CodePipelineTest.CONTROL_PREVIEW}
                  onClick={handlePreview}
                  variant="contained"
                  color="success"
                  endIcon={<RemoveRedEyeIcon />}
                >
                  {TextCommon.TEXT_BTN_PREVIEW}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <PreviewDialog open={openDialog} onClose={handleDialogClosed} />
    </>
  );
};

export default StepperControl;
