import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TextCommon } from '@Commons';
import { GLOBAL_MUI_SX } from '@Global';
import { StaticConfiguration, PreviewDialog } from '.';
import { useStaticHosting } from '@Pages/S3StaticHostingSetting/hooks';
import { ValidatorUtils } from '@Utils';
import { IStaticHostingSettingData } from '@Type';
const MUI_STYLE: SystemStyleObject = {
  ...GLOBAL_MUI_SX.flexVerticalGrow,
  border: '-moz-initial',
  borderRadius: 2,
  backgroundColor: '#fff',
};

const GRID_BUTTON_STYLE: SystemStyleObject = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
};

const BUTTON_STYLE: SystemStyleObject = { mr: 2 };

const Container = (): React.ReactElement => {
  const setting = useStaticHosting();
  const [isRegexPass, setIsRegexPass] = useState<boolean>(false);
  const [isValuePass, setIsValuePass] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const validateRegex = (setting: IStaticHostingSettingData): boolean => {
    const error: boolean = Object.keys(setting.staticHosting).some(
      propName => setting?.staticHosting[propName]?.isError === true,
    );
    if (error) {
      return false;
    } else {
      return true;
    }
  };

  const validateValue = (setting: IStaticHostingSettingData): boolean => {
    if (setting.staticHosting.customDomain.value === false) {
      const s3BucketNameError: boolean = ValidatorUtils.isEmptyStringForStaticHosting(
        setting.staticHosting.s3BucketName.value,
      );
      return !s3BucketNameError;
    } else {
      const listError: boolean[] = [
        ValidatorUtils.isEmptyStringForStaticHosting(setting.staticHosting.domainName.value),
        ValidatorUtils.isEmptyStringForStaticHosting(setting.staticHosting.hostZoneId.value),
      ];
      const isPass: boolean = listError.some(el => el === true);
      return !isPass;
    }
  };

  const handlePreview = (): void => {
    setOpenDialog(true);
  };

  const handleDialogClosed = (): void => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (setting) {
      setIsValuePass(validateValue(setting));
      setIsRegexPass(validateRegex(setting));
    }
  }, [setting]);

  return (
    <>
      <Box sx={MUI_STYLE}>
        <Box py={3} px={3} flexGrow={1}>
          <Grid container display="flex" flexDirection="column">
            <Typography variant="h5" sx={{ ...GLOBAL_MUI_SX.textCenter, fontWeight: 'bold' }}>
              {TextCommon.TEXT_AWS_STATIC_HOSTING}
            </Typography>
            <div>
              <StaticConfiguration />
            </div>
          </Grid>
        </Box>
      </Box>
      <Box py={2}>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} sx={GRID_BUTTON_STYLE}>
            <Button
              endIcon={<CheckCircleOutlineIcon />}
              variant="contained"
              sx={BUTTON_STYLE}
              disabled={isRegexPass && isValuePass ? false : true}
              onClick={(): void => handlePreview()}
            >
              {TextCommon.TEXT_BUTTON_SUBMIT}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <PreviewDialog open={openDialog} onClose={handleDialogClosed} />
    </>
  );
};

export default Container;
