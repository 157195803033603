import { Dispatch } from 'react';
import { AlertColor } from '@mui/material/Alert';
import { ENotifyType } from '@Type';

export interface IAppNotify {
  isOpen?: boolean;
  message?: string;
  type: AlertColor;
}

export type TAppDispatch = IAppNotify;

export type AppContextType = {
  notify: IAppNotify;
};

export enum EAppAction {
  SET_NOTIFY_OPEN = 'SET_NOTIFY_OPEN',
  SET_NOTIFY_CLOSE = 'SET_NOTIFY_CLOSE',
}

export type AppDispatchType<T> = {
  action: EAppAction;
  payload?: T;
};

class AppAction {
  private static _bindingPayloadNotifyOpen(message: string, type: AlertColor): AppDispatchType<IAppNotify> {
    const action = EAppAction.SET_NOTIFY_OPEN;
    return {
      action,
      payload: { message, type },
    };
  }

  static showError(dispatch: Dispatch<AppDispatchType<TAppDispatch>>, message: string): void {
    dispatch(AppAction._bindingPayloadNotifyOpen(message, ENotifyType.ERROR));
  }

  static showWarning(dispatch: Dispatch<AppDispatchType<TAppDispatch>>, message: string): void {
    dispatch(AppAction._bindingPayloadNotifyOpen(message, ENotifyType.WARN));
  }

  static showInfo(dispatch: Dispatch<AppDispatchType<TAppDispatch>>, message: string): void {
    dispatch(AppAction._bindingPayloadNotifyOpen(message, ENotifyType.INFO));
  }

  static showSuccess(dispatch: Dispatch<AppDispatchType<TAppDispatch>>, message: string): void {
    dispatch(AppAction._bindingPayloadNotifyOpen(message, ENotifyType.SUCCESS));
  }

  static close(dispatch: Dispatch<AppDispatchType<TAppDispatch>>): void {
    dispatch({
      action: EAppAction.SET_NOTIFY_CLOSE,
    });
  }
}

export default AppAction;
