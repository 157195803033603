import { useEffect } from 'react';
import { Grid, InputAdornment } from 'nxg-ui-wrapper';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

import { TextCommon } from '@Commons';
import { InputControl } from '@Components';
import { ICodePipelineSettingData } from '@Type';
import { ValidatorUtils } from '@Utils';
import useCodePipeline from '../hooks/useCodePipeline';
import { ActionHandler } from '../stores/action';
import { useCodePipelineDispatch } from '../hooks';
import { CodePipelineTest } from '_Test';

const MUI_STYLE: SxProps<Theme> = {
  justifyContent: 'center',
  display: 'flex',
};
interface ISecretManagerProps {
  // eslint-disable-next-line no-unused-vars
  onDenyNext: (value: boolean) => void;
}

const SecretsManager = (props: ISecretManagerProps): React.ReactElement => {
  const { secretsManager } = useCodePipeline() as ICodePipelineSettingData;
  const { name, tokenVersion } = secretsManager;
  const dispatch = useCodePipelineDispatch();

  useEffect(() => {
    const secretNameError = ValidatorUtils.isAllowWithRequired(secretsManager.name.isError);
    const tokenVersionError = ValidatorUtils.isAllowWithOptional(secretsManager.tokenVersion.isError);
    props.onDenyNext(secretNameError || tokenVersionError);
  }, [secretsManager]);

  const handleSecretName = (value: string): void => ActionHandler.setSecretManagerName(dispatch, value);

  return (
    <Grid container flexGrow={1} spacing={4} mt={2}>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={CodePipelineTest.FORM_SECRET_MANAGER}
          control={name}
          label={TextCommon.TEXT_SECRETS_MANAGER_NAME}
          helperText={TextCommon.TEXT_HELPER_SECRETS_MANAGER}
          required
          onChangeValue={handleSecretName}
          onUnFocusValue={handleSecretName}
        ></InputControl>
      </Grid>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={CodePipelineTest.FORM_KEY_VERSION_GITHUB_TOKEN}
          control={tokenVersion}
          label={TextCommon.TEXT_KEY_VERSION}
          helperText={TextCommon.TEXT_HELPER_KEY_VERSION}
          onChangeValue={(value: string): void => ActionHandler.setSecretManagerTokenVersion(dispatch, value)}
          inputProps={{
            startAdornment: <InputAdornment position="start">{TextCommon.TEXT_GITHUB_TOKEN}</InputAdornment>,
          }}
        ></InputControl>
      </Grid>
    </Grid>
  );
};

export default SecretsManager;
