import { Dispatch, createContext, useReducer } from 'react';

import { ICodePipelineSettingData } from '@Type';
import { ECodepipelineAction } from './action';
import codePipelineReducer, { INITIAL_SETTING_DATA } from './reducer';

export const CodePipelineContext = createContext<ICodePipelineSettingData | null>(null);
export const CodePipelineDispatchContext = createContext<Dispatch<ECodepipelineAction<any>> | null>(null);

const CodePipelineProvider = ({ children }): React.ReactElement => {
  const [setting, dispatch] = useReducer(codePipelineReducer, INITIAL_SETTING_DATA);

  return (
    <CodePipelineContext.Provider value={setting}>
      <CodePipelineDispatchContext.Provider value={dispatch}>{children}</CodePipelineDispatchContext.Provider>
    </CodePipelineContext.Provider>
  );
};

export default CodePipelineProvider;
