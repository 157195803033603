import { Dispatch, createContext, useReducer } from 'react';
import { IStaticHostingSettingData } from '@Type';
import { EStaticHostingAction } from './action';
import staticHostingReducer, { INITIAL_SETTING_DATA } from './reducer';

export const StaticHostingContext = createContext<IStaticHostingSettingData | null>(null);
export const StaticHostingDispatchContext = createContext<Dispatch<EStaticHostingAction<any>> | null>(null);

const StaticHostingProvider = ({ children }): React.ReactElement => {
  const [setting, dispatch] = useReducer(staticHostingReducer, INITIAL_SETTING_DATA);
  return (
    <StaticHostingContext.Provider value={setting}>
      <StaticHostingDispatchContext.Provider value={dispatch}>{children}</StaticHostingDispatchContext.Provider>
    </StaticHostingContext.Provider>
  );
};

export default StaticHostingProvider;
