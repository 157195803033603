import { Theme } from '@mui/material';
import { Box } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';

import { DefaultCommon, TextCommon } from '@Commons';
import { GLOBAL_MUI_SX } from '@Global';

const MUI_STYLE = (theme: Theme): SystemStyleObject => ({
  padding: 2,
  boxShadow: 3,
  bgcolor: theme.palette.primary.main,
  color: theme.palette.secondary.contrastText,
  ...GLOBAL_MUI_SX.fw500,
  ...GLOBAL_MUI_SX.textCenter,
  ...GLOBAL_MUI_SX.w100,
  ...GLOBAL_MUI_SX.borderBox,
});

const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const textFooter = [
    TextCommon.TEXT_COPYRIGHT,
    TextCommon.TEXT_COPYRIGHT_SYMBOL,
    currentYear,
    DefaultCommon.VAR_CHARACTER_HYPHENS,
    TextCommon.TEXT_COMPANY,
  ].join(DefaultCommon.VAR_CHARACTER_SPACE);

  return <Box sx={MUI_STYLE}>{textFooter}</Box>;
};

export default Footer;
