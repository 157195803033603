/* eslint-disable no-unused-vars */
interface IFieldInput<T> {
  value: T;
  isError?: boolean;
  errorMsg?: string[];
  helperStr?: string;
}
interface ISecretsManager {
  name: IFieldInput<string>;
  tokenVersion: IFieldInput<string>;
}

interface ISourceStage {
  name: IFieldInput<string>;
  actionName: IFieldInput<string>;
  githubBranch: IFieldInput<string>;
  githubOwner: IFieldInput<string>;
  githubRepo: IFieldInput<string>;
}

interface IBuildStage {
  name: IFieldInput<string>;
  actionName: IFieldInput<string>;
}

interface IDeployStage {
  name: IFieldInput<string>;
  createChangeSetActionName: IFieldInput<string>;
  createChangeSetActionMode: IFieldInput<string>;
  applyChangeSetActionName: IFieldInput<string>;
  applyChangeSetActionMode: IFieldInput<string>;
  stackName: IFieldInput<string>;
  templatePath: IFieldInput<string>;
  parameterOverrides: IFieldInput<object>;
}

interface ICodePipeline extends IPrepareResource {
  name: IFieldInput<string>;
  sourceStage: ISourceStage;
  buildStage: IBuildStage;
  deployStage: IDeployStage;
}

interface IPrepareResource {
  roleName: IFieldInput<string>;
  bucketName: IFieldInput<string>;
  deletionPolicy: IFieldInput<boolean>;
}

interface ICodeBuild extends IPrepareResource {
  name: IFieldInput<string>;
  buildSpec: IFieldInput<string>;
  computeType: IFieldInput<string>;
  containerType: IFieldInput<string>;
  image: IFieldInput<string>;
}

interface ICodePipelineSettingData {
  secretsManager: ISecretsManager;
  codeBuild: ICodeBuild;
  codePipeline: ICodePipeline;
  isValidSteps: IValidStep;
}
interface IStaticHostingSettingData {
  isValidSteps: IValidStep;
  staticHosting: IStaticHosting;
}
interface IStaticHosting {
  s3BucketName: IFieldInput<string>;
  username: IFieldInput<string>;
  password: IFieldInput<string>;
  domainName: IFieldInput<string>;
  timeToLive: IFieldInput<number>;
  hostZoneId: IFieldInput<string>;
  customDomain: IFieldInput<boolean>;
}

interface IValidStep {
  [x: number]: boolean;
}

enum EDeletionPolicy {
  DELETE = 'Delete',
  RETAIN = 'Retain',
}

enum ECodeBuildCompute {
  BUILD_GENERAL1_SMALL = 'BUILD_GENERAL1_SMALL',
  BUILD_GENERAL1_MEDIUM = 'BUILD_GENERAL1_MEDIUM',
  BUILD_GENERAL1_LARGE = 'BUILD_GENERAL1_LARGE',
}

enum ECodeBuildImageIdentifier {
  AMAZON_LINUX_2_STANDARD_4 = 'aws/codebuild/amazonlinux2-x86_64-standard:4.0',
  AMAZON_LINUX_2_STANDARD_5 = 'aws/codebuild/amazonlinux2-x86_64-standard:5.0',
}

enum ECodeBuildImagePlatform {
  AMAZON_LINUX_2 = 'Amazon Linux 2',
  AMAZON_LINUX_2023 = 'Amazon Linux 2023',
  UBUNTU_18_04 = 'Ubuntu 18.04',
  UBUNTU_20_04 = 'Ubuntu 20.04',
  UBUNTU_22_04 = 'Ubuntu 22.04',
  WD_SERVER_CORE_2019 = 'Windows Server Core 2019',
}

enum ECodeBuildContainer {
  ARM_CONTAINER = 'ARM_CONTAINER',
  LINUX_CONTAINER = 'LINUX_CONTAINER',
  LINUX_GPU_CONTAINER = 'LINUX_GPU_CONTAINER',
  WINDOWS_CONTAINER = 'WINDOWS_CONTAINER',
  WINDOWS_SERVER_2019_CONTAINER = 'WINDOWS_SERVER_2019_CONTAINER',
}

enum ECodePipelineActionMode {
  CHANGE_SET_EXECUTE = 'CHANGE_SET_EXECUTE',
  CHANGE_SET_REPLACE = 'CHANGE_SET_REPLACE',
  CREATE_UPDATE = 'CREATE_UPDATE',
  DELETE_ONLY = 'DELETE_ONLY',
  REPLACE_ON_FAILURE = 'REPLACE_ON_FAILURE',
}

interface ComputeTypeItem {
  value: ECodeBuildCompute;
  desc: string;
}

interface ImageTypeItem {
  desc: ECodeBuildImagePlatform;
  value: ECodeBuildImageIdentifier;
}

interface ContainerTypeItem {
  value: ECodeBuildContainer;
  desc: string;
}

interface ActionModeItem {
  value: ECodePipelineActionMode;
  desc: string;
}

type CodeBuildCompute = ComputeTypeItem[];
type CodeBuildImage = ImageTypeItem[];
type CodeBuildContainer = ContainerTypeItem[];
type CodePipelineAction = ActionModeItem[];

enum ECfnFormat {
  JSON = 'json',
  YAML = 'yaml',
}

enum ENotifyType {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type {
  IFieldInput,
  ISecretsManager,
  IStaticHosting,
  ICodeBuild,
  ICodePipeline,
  ICodePipelineSettingData,
  IStaticHostingSettingData,
  IValidStep,
  ISourceStage,
  IBuildStage,
  IDeployStage,
  CodeBuildCompute,
  ComputeTypeItem,
  CodeBuildImage,
  ImageTypeItem,
  CodeBuildContainer,
  ContainerTypeItem,
  CodePipelineAction,
  ActionModeItem,
};
export {
  ECodeBuildCompute,
  ECodeBuildImageIdentifier,
  ECodeBuildImagePlatform,
  ECodeBuildContainer,
  ECodePipelineActionMode,
  EDeletionPolicy,
  ECfnFormat,
  ENotifyType,
};
