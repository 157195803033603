import CodePipelineSetting from '@Pages/CodePipelineSetting';
import S3StaticHostingSetting from '@Pages/S3StaticHostingSetting';
import NotFoundPage from '@Pages/NotFoundPage';

import MainLayout from '@Layouts/MainLayout';
import { DefaultCommon, TextCommon } from '@Commons';
import React from 'react';

export interface IRouter {
  path: string;
  element: () => React.ReactElement;
  children?: IRouter[];
  index?: false;
}

const appRouter: IRouter[] = [
  {
    path: DefaultCommon.CONST_CODE_PIPELINE_PATH,
    element: MainLayout,
    children: [
      {
        path: TextCommon.TEXT_EMPTY_STRING,
        element: CodePipelineSetting,
      },
    ],
  },
  {
    path: DefaultCommon.CONST_STATIC_HOSTING_PATH,
    element: MainLayout,
    children: [
      {
        path: TextCommon.TEXT_EMPTY_STRING,
        element: S3StaticHostingSetting,
      },
    ],
  },
  {
    path: TextCommon.TEXT_START_STRING,
    element: NotFoundPage,
  },
];

export default appRouter;
