/* eslint-disable @typescript-eslint/ban-types */
import { Dispatch, createContext, useReducer } from 'react';

import appReducer, { APP_INITIAL } from './reducer';
import { AppContextType, AppDispatchType, TAppDispatch } from './action';

const AppContextState: AppContextType | {} = {};

export const AppContext = createContext<AppContextType | {}>(AppContextState);

export const AppDispatchContext = createContext<Dispatch<AppDispatchType<TAppDispatch>> | null>(null);

const AppProvider = ({ children }): React.ReactElement => {
  const [app, dispatch] = useReducer(appReducer, APP_INITIAL);

  return (
    <AppContext.Provider value={app}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

export default AppProvider;
