import { v4 as uuidv4 } from 'uuid';

class GeneratorUtils {
  private constructor() {}
  static newUUIDv4(): string {
    return uuidv4();
  }
}

export default GeneratorUtils;
