class CodePipelineTest {
  public static readonly FORM_SECRET_MANAGER = 'form-secret-manager';
  public static readonly FORM_KEY_VERSION_GITHUB_TOKEN = 'form-github-token';
  public static readonly FORM_CODE_BUILD_NAME = 'form-code-build-name';
  public static readonly FORM_CODE_BUILD_SPEC_FILE = 'form-code-build-spec-file';
  public static readonly FORM_CODE_BUILD_COMPUTE_TYPE = 'form-code-build-compute-type';
  public static readonly FORM_CODE_BUILD_IMAGE_TYPE = 'form-code-build-image-type';
  public static readonly FORM_CODE_BUILD_CONTAINER_TYPE = 'form-code-build-container-type';
  public static readonly FORM_CODE_BUILD_ROLE_NAME = 'form-code-build-role-name';
  public static readonly FORM_CODE_BUILD_BUCKET_NAME = 'form-code-build-bucket-name';
  public static readonly FORM_CODE_BUILD_BUCKET_DELETION = 'form-code-build-bucket-deletion';
  public static readonly FORM_CODE_PIPELINE_NAME = 'form-code-pipeline-name';
  public static readonly FORM_CODE_PIPELINE_ROLE_NAME = 'form-code-pipeline-role-name';
  public static readonly FORM_CODE_PIPELINE_BUCKET_NAME = 'form-code-pipeline-bucket-name';
  public static readonly FORM_CODE_PIPELINE_BUCKET_DELETION = 'form-code-pipeline-bucket-deletion';
  public static readonly FORM_CODE_PIPELINE_STAGE_SOURCE_NAME = 'form-code-pipeline-stage-source-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_SOURCE_ACTION_NAME = 'form-code-pipeline-stage-source-action-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_OWNER = 'form-code-pipeline-stage-source-github-owner';
  public static readonly FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_REPO = 'form-code-pipeline-stage-source-github-repo';
  public static readonly FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_BRANCH =
    'form-code-pipeline-stage-source-github-branch';
  public static readonly FORM_CODE_PIPELINE_STAGE_BUILD_NAME = 'form-code-pipeline-stage-build-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_BUILD_ACTION_NAME = 'form-code-pipeline-stage-build-action-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_DEPLOY_NAME = 'form-code-pipeline-stage-deploy-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME =
    'form-code-pipeline-stage-deploy-create-change-set-action-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME =
    'form-code-pipeline-stage-deploy-apply-change-set-action-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_DEPLOY_STACK_NAME = 'form-code-pipeline-stage-deploy-stack-name';
  public static readonly FORM_CODE_PIPELINE_STAGE_DEPLOY_TEMPLATE = 'form-code-pipeline-stage-deploy-template';
  public static readonly CLASS_NAME_REACT_JSON_VIEW = 'react-json-view';
  public static readonly CONTROL_PREV = 'control-prev';
  public static readonly CONTROL_NEXT = 'control-next';
  public static readonly CONTROL_FINISH = 'control-finish';
  public static readonly CONTROL_PREVIEW = 'control-preview';
  public static readonly CONTROL_RESET = 'control-reset';
  public static readonly CONTROL_SUB_PREV = 'control-sub-prev';
  public static readonly CONTROL_SUB_NEXT = 'control-sub-next';
}

export default CodePipelineTest;
