class S3StaticHostingTest {
  public static readonly FORM_STATIC_HOSTING_DOMAIN_NAME = 'form-static-hosting-domain-name';
  public static readonly FORM_STATIC_HOSTING_HOST_ZONE_ID = 'form-static-hosting-zone-id';
  public static readonly FORM_STATIC_HOSTING_TTL = 'form-static-hosting-ttl';
  public static readonly FORM_STATIC_HOSTING_USERNAME = 'form-static-hosting-username';
  public static readonly FORM_STATIC_HOSTING_PASSWORD = 'form-static-hosting-password';
  public static readonly FORM_STATIC_HOSTING_BUCKET_NAME = 'form-static-hosting-bucket-name';
}

export default S3StaticHostingTest;
