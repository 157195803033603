import { useEffect } from 'react';
import { Grid } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';

import { EInputType, InputControl } from '@Components';
import { ICodePipelineSettingData } from '@Type';
import { TextCommon } from '@Commons';
import { ValidatorUtils } from '@Utils';
import { GLOBAL_MUI_SX } from '@Global';

import { CODEBUILD_COMPUTE_TYPES, CODEBUILD_IMAGES, CODEBUILD_CONTAINER_TYPES } from '../common';
import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { ActionHandler } from '../stores/action';
import { CodePipelineTest } from '_Test';

const INPUT_STYLE: SystemStyleObject = {
  ...GLOBAL_MUI_SX.flexHorizontalGrow,
  justifyContent: 'center',
};

interface ICodeBuildProps {
  onDenyNext: (value: boolean) => void;
}

const CodeBuild = ({ onDenyNext }: ICodeBuildProps): React.ReactElement => {
  const { codeBuild } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();

  useEffect(() => {
    const nameError = ValidatorUtils.isAllowWithOptional(codeBuild.name.isError);
    const buildSpecError = ValidatorUtils.isAllowWithRequired(codeBuild.buildSpec.isError);
    onDenyNext(nameError || buildSpecError);
  }, [codeBuild.name, codeBuild.buildSpec]);

  return (
    <Grid container flexGrow={1} spacing={4} mt={2} component="form">
      <Grid item xs={6}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_NAME}
          control={codeBuild.name}
          label={TextCommon.TEXT_CODEBUILD_NAME}
          helperText={TextCommon.TEXT_HELPER_CODEBUILD_NAME}
          onChangeValue={(value: string): void => ActionHandler.setCodebuildName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_SPEC_FILE}
          control={codeBuild.buildSpec}
          label={TextCommon.TEXT_BUILDSPEC_FILE}
          helperText={TextCommon.TEXT_HELPER_CODEBUILD_SPEC}
          onChangeValue={(value: string): void => ActionHandler.setBuildSpec(dispatch, value)}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_COMPUTE_TYPE}
          type={EInputType.SELECT}
          required
          control={codeBuild.computeType}
          label={TextCommon.TEXT_COMPUTE_TYPE}
          helperText={TextCommon.TEXT_HELPER_CODEBUILD_COMPUTE_TYPE}
          SelectItems={CODEBUILD_COMPUTE_TYPES}
          onChangeValue={(value: string): void => ActionHandler.setComputeType(dispatch, value)}
        />
      </Grid>
      <Grid item xs={12} sx={INPUT_STYLE}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_IMAGE_TYPE}
          type={EInputType.SELECT}
          required
          control={codeBuild.image}
          label={TextCommon.TEXT_IMAGE_TYPE}
          helperText={TextCommon.TEXT_HELPER_CODEBUILD_IMAGE_TYPE}
          SelectItems={CODEBUILD_IMAGES}
          onChangeValue={(value: string): void => ActionHandler.setImageType(dispatch, value)}
        />
      </Grid>
      <Grid item xs={12} sx={INPUT_STYLE}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_CONTAINER_TYPE}
          type={EInputType.SELECT}
          required
          control={codeBuild.containerType}
          label={TextCommon.TEXT_CONTAINER_TYPE}
          helperText={TextCommon.TEXT_HELPER_CODEBUILD_CONTAINER_TYPE}
          SelectItems={CODEBUILD_CONTAINER_TYPES}
          onChangeValue={(value: string): void => ActionHandler.setContainerType(dispatch, value)}
        />
      </Grid>
    </Grid>
  );
};

export default CodeBuild;
