/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CSSProperties, useEffect } from 'react';
import { FormHelperText, Grid, Typography } from 'nxg-ui-wrapper';

import ReactJson, { InteractionProps } from '@dinuac/react-json-view';

import { TextCommon } from '@Commons';
import { GLOBAL_MUI_SX } from '@Global';
import { InputControl } from '@Components';

import { ICodePipelineSettingData } from '@Type';
import { ValidatorUtils } from '@Utils';

import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { ActionHandler } from '../stores/action';
import { CodePipelineTest } from '_Test';

const REACT_JSON_STYLE: CSSProperties = {
  padding: '10px',
  borderRadius: '3px',
  margin: '10px 0px',
  ...GLOBAL_MUI_SX.w100,
} as CSSProperties;

interface IStageDeployProps {
  onDenyNext: (value: boolean) => void;
}

const StageDeploy = ({ onDenyNext }: IStageDeployProps): React.ReactElement => {
  const { codePipeline } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();
  const { deployStage } = codePipeline;

  useEffect(() => {
    const nameError = ValidatorUtils.isAllowWithOptional(deployStage.name.isError);
    const createChangeSetActionNameError = ValidatorUtils.isAllowWithOptional(
      deployStage.createChangeSetActionName.isError,
    );
    const applyChangeSetActionNameError = ValidatorUtils.isAllowWithOptional(
      deployStage.applyChangeSetActionName.isError,
    );
    const pathError = ValidatorUtils.isAllowWithRequired(deployStage.templatePath.isError);
    const stackNameError = ValidatorUtils.isAllowWithRequired(deployStage.stackName.isError);
    const isNext =
      nameError || createChangeSetActionNameError || applyChangeSetActionNameError || pathError || stackNameError;
    onDenyNext(isNext);
  }, [
    deployStage.name,
    deployStage.createChangeSetActionName,
    deployStage.applyChangeSetActionName,
    deployStage.stackName,
    deployStage.templatePath,
  ]);

  const handleParameter = (value: object): void => {
    ActionHandler.setStageDeployParameter(dispatch, value);
  };
  const handleStackName = (value: string): void => ActionHandler.setStageDeployStackName(dispatch, value);
  const handleTemplatePath = (value: string): void => ActionHandler.setStageDeployTemplatePath(dispatch, value);

  return (
    <Grid container flexGrow={1} spacing={4} component="form">
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_DEPLOY_NAME}
          control={codePipeline.deployStage.name}
          label={TextCommon.TEXT_STAGE_NAME}
          helperText={TextCommon.TEXT_HELPER_STAGE_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageDeployName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={6} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME}
          control={codePipeline.deployStage.createChangeSetActionName}
          label={TextCommon.TEXT_ACTION_CREATE_CHANGE_SET_NAME}
          helperText={TextCommon.TEXT_HELPER_ACTION_CREATE_CHANGE_SET_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageDeployCreateChangeSetName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={6} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME}
          control={codePipeline.deployStage.applyChangeSetActionName}
          label={TextCommon.TEXT_ACTION_APPLY_CHANGE_SET_NAME}
          helperText={TextCommon.TEXT_HELPER_ACTION_APPLY_CHANGE_SET_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageDeployApplyChangeSetName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_DEPLOY_STACK_NAME}
          control={codePipeline.deployStage.stackName}
          label={TextCommon.TEXT_STACK_NAME}
          helperText={TextCommon.TEXT_HELPER_STACK_NAME}
          onChangeValue={handleStackName}
          onUnFocusValue={handleStackName}
          required
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_DEPLOY_TEMPLATE}
          control={codePipeline.deployStage.templatePath}
          label={TextCommon.TEXT_TEMPLATE_PATH}
          helperText={TextCommon.TEXT_HELPER_TEMPLATE_PATH}
          onChangeValue={handleTemplatePath}
          onUnFocusValue={handleTemplatePath}
          required
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter} display={'flex'} flexDirection={'column'}>
        <Typography variant="h6" component="div">
          {TextCommon.TEXT_PARAMETER_OVERRIDES}
        </Typography>
        <FormHelperText>{TextCommon.TEXT_HELPER_PARAMETERS}</FormHelperText>
        <ReactJson
          data-cy={CodePipelineTest.CLASS_NAME_REACT_JSON_VIEW}
          name={false}
          theme="hopscotch"
          collapsed={false}
          collapseStringsAfterLength={false}
          displayObjectSize={true}
          enableClipboard={true}
          indentWidth={4}
          displayDataTypes={false}
          iconStyle="circle"
          onEdit={(event: InteractionProps) => {
            handleParameter(event.updated_src);
          }}
          onAdd={(event: InteractionProps) => {
            handleParameter(event.updated_src);
          }}
          onDelete={(event: InteractionProps) => {
            handleParameter(event.updated_src);
          }}
          style={REACT_JSON_STYLE}
          src={codePipeline.deployStage.parameterOverrides.value}
        />
      </Grid>
    </Grid>
  );
};

export default StageDeploy;
