class TextCommon {
  public static readonly TEXT_COPYRIGHT = `Copyright`;
  public static readonly TEXT_COPYRIGHT_SYMBOL = `©`;
  public static readonly TEXT_TOKYO_SEIMITSU = `TOKYO SEIMITSU`;
  public static readonly TEXT_COMPANY = `${TextCommon.TEXT_TOKYO_SEIMITSU} CO., LTD`;
  public static readonly TEXT_ACCRETECH = `Accretech`;

  public static readonly TEXT_AWS_CODEPIPELINE = `AWS CodePipeline`;
  public static readonly TEXT_AWS_CODEBUILD = `AWS CodeBuild`;
  public static readonly TEXT_AWS_STATIC_HOSTING = `S3 Static Hosting`;

  public static readonly TEXT_FILE = `File`;
  public static readonly TEXT_CODEPIPELINE_GENERATION = `CodePipeline Generation`;
  public static readonly TEXT_CODEPIPELINE_GENERATION_DESCRIPTION = `The client application supports the implementation of configurations to build the testing environment`;
  public static readonly TEXT_STAGE_NAME = `Stage Name`;
  public static readonly TEXT_ACTION_NAME = `Action Name`;
  public static readonly TEXT_ACTION_CREATE_CHANGE_SET_NAME = `Action Name (Create Change Set)`;
  public static readonly TEXT_ACTION_APPLY_CHANGE_SET_NAME = `Action Name (Apply Change Set)`;
  public static readonly TEXT_GITHUB_OWNER = `GitHub Owner`;
  public static readonly TEXT_GITHUB_REPO = `GitHub Repository`;
  public static readonly TEXT_GITHUB_BRANCH = `GitHub Branch`;
  public static readonly TEXT_SECRETS_MANAGER_NAME = `Secrets Manager Name`;
  public static readonly TEXT_KEY_VERSION = `Key Version`;
  public static readonly TEXT_GITHUB_TOKEN = `GITHUB_TOKEN`;
  public static readonly TEXT_ROLE_NAME = `Role Name`;
  public static readonly TEXT_BUCKET_NAME = `Bucket Name`;
  public static readonly TEXT_DELETION_POLICY_S3_BUCKET = `Deletion Policy (S3 Bucket)`;
  public static readonly TEXT_CODEBUILD_NAME = `CodeBuild Name`;
  public static readonly TEXT_COMPUTE_TYPE = `Compute Type`;
  public static readonly TEXT_CONTAINER_TYPE = `Container Type`;
  public static readonly TEXT_IMAGE_TYPE = `Image Type`;
  public static readonly TEXT_STACK_NAME = `Stack Name`;
  public static readonly TEXT_ACTION_MODE = `Action Mode`;
  public static readonly TEXT_TEMPLATE_PATH = `Template Path`;
  public static readonly TEXT_PARAMETER_OVERRIDES = `Parameter Overrides`;
  public static readonly TEXT_BUILDSPEC_FILE = `BuildSpec File`;
  public static readonly TEXT_PREVIEW = `Preview`;
  public static readonly TEXT_CODEPIPELINE_NAME = `CodePipeline Name`;
  public static readonly TEXT_STATICHOSTING_GENERATION = `Static Generation`;
  public static readonly TEXT_STATICHOSTING_GENERATION_DESCRIPTION = `The client application supports implementing configurations to host the S3 static website`;
  public static readonly TEXT_STATIC_HOSTING_GENERATION = `Static hosting Generation`;
  public static readonly TEXT_USERNAME = `Username`;
  public static readonly TEXT_PASSWORD = `Password`;
  public static readonly TEXT_TIME_TO_LIVE = `Time To Live`;
  public static readonly TEXT_DOMAIN_NAME = `Domain Name`;
  public static readonly TEXT_HOST_ZONE_NAME = `Route53 Hosted zone name`;
  public static readonly TEXT_HOST_ZONE_ID = `Route53 Hosted Zone Id`;
  public static readonly TEXT_HOSTING_OPTION = `Custom Domain`;

  public static readonly TEXT_HELPER_SECRETS_MANAGER = `The name or ARN of the Secrets Manager`;
  public static readonly TEXT_HELPER_KEY_VERSION = `A secret has versions which hold copies of the encrypted secret value. When change the secret value, or the secret is rotated, Secrets Manager creates a new version`;
  public static readonly TEXT_HELPER_ROLE_NAME = `A name for the IAM role`;
  public static readonly TEXT_HELPER_BUCKET_NAME = `The Bucket Name of the S3`;
  public static readonly TEXT_HELPER_DELETION_POLICY = `With the DeletionPolicy attribute you can preserve, and in some cases, backup a resource when its stack is deleted`;
  public static readonly TEXT_HELPER_CODEBUILD_NAME = `The name of the build project. The name must be unique across all of the projects in your AWS account`;
  public static readonly TEXT_HELPER_CODEBUILD_SPEC = `The build specification for the project`;
  public static readonly TEXT_HELPER_CODEBUILD_COMPUTE_TYPE = `The type of compute environment. This determines the number of CPU cores and memory the build environment uses`;
  public static readonly TEXT_HELPER_CODEBUILD_IMAGE_TYPE = `The image tag or image digest that identifies the Docker image to use for this build project`;
  public static readonly TEXT_HELPER_CODEBUILD_CONTAINER_TYPE = `The type of build environment to use for related builds`;
  public static readonly TEXT_HELPER_CODEPIPELINE_NAME = `The name of the CodePipeline`;
  public static readonly TEXT_HELPER_AUTO_INITIAL = `If not specified, the CloudFormation will be initialized automatically during Template deployment (By Logical Id)`;
  public static readonly TEXT_HELPER_STAGE_NAME = `The name of stage will be display in CodePipeline`;
  public static readonly TEXT_HELPER_ACTION_NAME = `The name action of stage will be display in CodePipeline`;
  public static readonly TEXT_HELPER_ACTION_CREATE_CHANGE_SET_NAME = `The action name of create change set stage to be displayed in CodePipeline`;
  public static readonly TEXT_HELPER_ACTION_APPLY_CHANGE_SET_NAME = `The action name of apply change set stage to be displayed in CodePipeline`;
  public static readonly TEXT_HELPER_GITHUB_OWNER = `The name of the GitHub user or organization who owns the GitHub repository`;
  public static readonly TEXT_HELPER_GITHUB_REPO = `The name of the repository where source changes are to be detected`;
  public static readonly TEXT_HELPER_GITHUB_BRANCH = `The name of the branch where source changes are to be detected`;
  public static readonly TEXT_HELPER_STACK_NAME = `The name of an existing stack or a stack that want to create`;
  public static readonly TEXT_HELPER_ACTION_MODE = `The name of the action AWS CloudFormation performs on a stack or change set`;
  public static readonly TEXT_HELPER_TEMPLATE_PATH = `Represents the AWS CloudFormation template file`;
  public static readonly TEXT_HELPER_PARAMETERS = `Parameters are defined in your stack template and allow you to provide values for them at the time of stack creation or update`;
  public static readonly TEXT_HELPER_DOMAIN_NAME = `The Domain name that you want to create`;
  public static readonly TEXT_HELPER_HOST_ZONE_NAME = `Go to Route53 find your host zone name`;
  public static readonly TEXT_HELPER_HOST_ZONE_ID = `Go to Route53 find your host zone id`;
  public static readonly TEXT_HELPER_OPTIONAL_HOSTING = `Check if you want to custom your domain `;
  public static readonly TEXT_HELPER_USERNAME = `The name use to login static hosting`;
  public static readonly TEXT_HELPER_PASSWORD = `The password use to login static hosting`;
  public static readonly TEXT_HELPER_TIME_TO_LIVE = `The time (in seconds) that the content is cached`;

  public static readonly TEXT_BTN_BACK = `Back`;
  public static readonly TEXT_BTN_NEXT = `Next`;
  public static readonly TEXT_BTN_PREVIEW = `Review`;
  public static readonly TEXT_BTN_FINISH = `Finish`;
  public static readonly TEXT_BTN_RESET = `Reset`;
  public static readonly TEXT_BTN_PREV = `Prev`;
  public static readonly TEXT_BTN_DOWNLOAD = `Download`;
  public static readonly TEXT_BTN_COPY = `Copy`;
  public static readonly TEXT_BTN_JSON = `Json`;
  public static readonly TEXT_BTN_YAML = `Yaml`;
  public static readonly TEXT_BUTTON_SUBMIT = `Submit`;

  public static readonly TEXT_DOUBLE_OUBLE = `""`;
  public static readonly TEXT_EMPTY_STRING = '';
  public static readonly TEXT_START_STRING = `*`;
}

export default TextCommon;
