import { useState } from 'react';
import { Box, Button, Grid } from 'nxg-ui-wrapper';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { SystemStyleObject } from '@mui/system';

import { useStepperControl } from '@Hooks';
import { HorizontalStepper } from '@Components';
import { TextCommon } from '@Commons';

import { DEFAULT_SUB_STEPPERS } from '../common';
import StageSource from './StageSource';
import StageBuild from './StageBuild';
import StageDeploy from './StageDeploy';
import CodePipelineDesc from './CodePipelineDesc';
import { useCodePipelineDispatch } from '../hooks';
import { ActionHandler } from '../stores/action';
import { CodePipelineTest } from '_Test';

const BOX_WRAPPER_STYLE: SystemStyleObject = {
  mt: 1,
  display: 'flex',
  flexDirection: 'column',
};
const BOX_RENDER_STYLE: SystemStyleObject = {
  p: 3,
  mt: 2,
  flexGrow: 1,
};
const BOX_BUTTON_STYLE: SystemStyleObject = {
  textAlign: 'center',
  py: 2,
};

const CodePipeline = (): React.ReactElement => {
  const { steps, activeStep, action } = useStepperControl({ data: DEFAULT_SUB_STEPPERS });
  const [denyNext, setDenyNext] = useState<boolean>(false);
  const dispatch = useCodePipelineDispatch();

  const handleDenyNext = (value: boolean): void => {
    setDenyNext(value);
    ActionHandler.setValidSteps(dispatch, activeStep, !value);
  };

  const handleNext = (): void => {
    action.handleNextAction();
    ActionHandler.setValidSteps(dispatch, activeStep, true);
  };

  const renderCase = (): React.ReactElement | undefined => {
    const stepCases = [
      <CodePipelineDesc onDenyNext={handleDenyNext} />,
      <StageSource onDenyNext={handleDenyNext} />,
      <StageBuild onDenyNext={handleDenyNext} />,
      <StageDeploy onDenyNext={handleDenyNext} />,
    ];
    return stepCases[activeStep];
  };

  return (
    <Box sx={BOX_WRAPPER_STYLE}>
      <Box sx={BOX_BUTTON_STYLE}>
        <Button
          data-cy={CodePipelineTest.CONTROL_SUB_PREV}
          variant="contained"
          sx={{ mr: 1 }}
          color="success"
          disabled={action.isFirst}
          onClick={action.handleBackAction}
          startIcon={<ArrowLeftIcon />}
        >
          {TextCommon.TEXT_BTN_PREV}
        </Button>
        <Button
          data-cy={CodePipelineTest.CONTROL_SUB_NEXT}
          variant="contained"
          color="success"
          disabled={action.isLast || denyNext}
          onClick={handleNext}
          endIcon={<ArrowRightIcon />}
        >
          {TextCommon.TEXT_BTN_NEXT}
        </Button>
      </Box>
      <HorizontalStepper steps={steps} activeStep={activeStep} />
      <Box sx={BOX_RENDER_STYLE}>
        <Grid container display="flex" flexDirection="column">
          {renderCase()}
        </Grid>
      </Box>
    </Box>
  );
};

export default CodePipeline;
