class BrowserUtils {
  static clipboard(data: string): void {
    navigator.clipboard.writeText(data);
  }

  static downloadFile(data: string, type: string, fileName: string): void {
    const blob = new Blob([data], { type });
    BrowserUtils.downloadFileWithBlob(blob, fileName);
  }

  static downloadFileWithBlob(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
  }
}

export default BrowserUtils;
