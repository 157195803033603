import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ECfnFormat, IStaticHostingSettingData, IStaticHosting } from '@Type';
import { DefaultCommon, MsgCommon, TextCommon } from '@Commons';
import { S3_STATIC_HOSTING_CFN_TEMPLATE } from '@Assets';
import { useAppDispatch } from '@Hooks';
import { AppAction } from '@Stores';
import { GLOBAL_MUI_SX } from '@Global';
import { CustomizedMenus } from '@Components';
import { BrowserUtils, GeneratorUtils, StringUtils, TransitionUtils } from '@Utils';
import { useStaticHosting } from '../hooks';

const TYPO_STYLE: SystemStyleObject = { ml: 2, flex: 1 };

interface IPreviewDialogProps {
  open: boolean;
  onClose: () => void;
}

interface IObjectStaticHosting {
  staticHosting: IStaticHosting;
}

const PreviewDialog = ({ open, onClose }: IPreviewDialogProps): React.ReactElement => {
  const { staticHosting } = useStaticHosting() as IStaticHostingSettingData;
  const appDispatch = useAppDispatch();

  const [alignment, setAlignment] = React.useState<ECfnFormat>(ECfnFormat.YAML);
  const downloadRef = React.useRef();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: ECfnFormat): void => {
    setAlignment(newAlignment);
  };

  const getHostingReferentObject = (
    option: boolean,
    staticHostingTemp: IStaticHosting,
  ): IObjectStaticHosting | null => {
    return {
      staticHosting: {
        s3BucketName: staticHostingTemp.s3BucketName,
        username: staticHostingTemp.username,
        password: staticHostingTemp.password,
        timeToLive: staticHostingTemp.timeToLive,
        domainName: option ? staticHostingTemp.domainName : { value: TextCommon.TEXT_DOUBLE_OUBLE },
        hostZoneId: option ? staticHostingTemp.hostZoneId : { value: TextCommon.TEXT_DOUBLE_OUBLE },
        customDomain: staticHostingTemp.customDomain,
      },
    };
  };

  const renderData = React.useCallback(
    (type: ECfnFormat) => {
      const object: IObjectStaticHosting | null = getHostingReferentObject(
        staticHosting.customDomain.value,
        staticHosting,
      );
      const transformData: string = StringUtils.format(S3_STATIC_HOSTING_CFN_TEMPLATE, object);

      if (type === ECfnFormat.JSON && transformData) {
        return TransitionUtils.parseYamlToJsonStr(transformData);
      }

      return transformData;
    },
    [alignment, open],
  );

  const handleClose = (): void => {
    onClose();
  };

  const handleCopy = (): void => {
    BrowserUtils.clipboard(renderData(alignment));
    AppAction.showSuccess(appDispatch, MsgCommon.COPY_SUCCESSFUL);
  };

  const handleDownloadYaml = (): void => {
    (downloadRef.current as any)?.close();
    BrowserUtils.downloadFile(
      renderData(ECfnFormat.YAML),
      DefaultCommon.MIME_TYPE_YAML,
      DefaultCommon.EXPORT_CFN_STATIC_HOSTING_YAML_FILENAME,
    );
    AppAction.showSuccess(
      appDispatch,
      StringUtils.format(MsgCommon.DOWNLOAD_SUCCESSFUL, TextCommon.TEXT_BTN_YAML.toUpperCase()),
    );
  };

  const handleDownloadJson = (): void => {
    (downloadRef.current as any)?.close();
    BrowserUtils.downloadFile(
      renderData(ECfnFormat.JSON),
      DefaultCommon.MIME_TYPE_JSON,
      DefaultCommon.EXPORT_CFN_STATIC_HOSTING_JSON_FILENAME,
    );
    AppAction.showSuccess(
      appDispatch,
      StringUtils.format(MsgCommon.DOWNLOAD_SUCCESSFUL, TextCommon.TEXT_BTN_JSON.toUpperCase()),
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={GLOBAL_MUI_SX.positionFixed}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={TYPO_STYLE} variant="h6" component="div">
            {TextCommon.TEXT_CODEPIPELINE_GENERATION} {TextCommon.TEXT_PREVIEW}
          </Typography>
          <Button
            sx={{ mr: 2 }}
            autoFocus
            color="success"
            variant="contained"
            onClick={handleCopy}
            startIcon={<ContentCopyIcon />}
          >
            {TextCommon.TEXT_BTN_COPY}
          </Button>
          <CustomizedMenus
            ref={downloadRef}
            text={TextCommon.TEXT_BTN_DOWNLOAD}
            menuItems={[
              <MenuItem key={GeneratorUtils.newUUIDv4()} disableRipple onClick={handleDownloadYaml}>
                <CloudDownloadIcon />
                {TextCommon.TEXT_BTN_YAML} {TextCommon.TEXT_FILE}
              </MenuItem>,
              <MenuItem key={GeneratorUtils.newUUIDv4()} disableRipple onClick={handleDownloadJson}>
                <CloudDownloadIcon />
                {TextCommon.TEXT_BTN_JSON} {TextCommon.TEXT_FILE}
              </MenuItem>,
            ]}
          />
        </Toolbar>
      </AppBar>
      <Box py={2} mx={4} mt={8}>
        <Grid container>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value={ECfnFormat.YAML.toString()}>{TextCommon.TEXT_BTN_YAML}</ToggleButton>
              <ToggleButton value={ECfnFormat.JSON.toString()}>{TextCommon.TEXT_BTN_JSON}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <SyntaxHighlighter
              language={alignment}
              style={nightOwl}
              showLineNumbers
              showInlineLineNumbers
              wrapLongLines
            >
              {renderData(alignment)}
            </SyntaxHighlighter>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PreviewDialog;
