import { useEffect } from 'react';
import { Grid } from 'nxg-ui-wrapper';

import { TextCommon } from '@Commons';
import { InputControl } from '@Components';
import { GLOBAL_MUI_SX } from '@Global';
import { ICodePipelineSettingData } from '@Type';
import { ValidatorUtils } from '@Utils';

import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { ActionHandler } from '../stores/action';
import { CodePipelineTest } from '_Test';

interface IStageSourceProps {
  onDenyNext: (value: boolean) => void;
}

const StageSource = ({ onDenyNext }: IStageSourceProps): React.ReactElement => {
  const { codePipeline } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();
  const { sourceStage } = codePipeline;

  useEffect(() => {
    const nameError = ValidatorUtils.isAllowWithOptional(sourceStage.name.isError);
    const actionNameError = ValidatorUtils.isAllowWithOptional(sourceStage.actionName.isError);
    const branchError = ValidatorUtils.isAllowWithRequired(sourceStage.githubBranch.isError);
    const repoError = ValidatorUtils.isAllowWithRequired(sourceStage.githubRepo.isError);
    const ownerError = ValidatorUtils.isAllowWithRequired(sourceStage.githubOwner.isError);
    const isNext = nameError || actionNameError || branchError || repoError || ownerError;
    onDenyNext(isNext);
  }, [
    sourceStage.name,
    sourceStage.actionName,
    sourceStage.githubBranch,
    sourceStage.githubRepo,
    sourceStage.githubOwner,
  ]);

  const handleGithubOwner = (value: string): void => ActionHandler.setStageSourceOwner(dispatch, value);
  const handleGithubRepo = (value: string): void => ActionHandler.setStageSourceRepo(dispatch, value);
  const handleGithubBranch = (value: string): void => ActionHandler.setStageSourceBranch(dispatch, value);

  return (
    <Grid container flexGrow={1} spacing={3} component="form">
      <Grid item xs={6} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_SOURCE_NAME}
          control={codePipeline.sourceStage.name}
          label={TextCommon.TEXT_STAGE_NAME}
          helperText={TextCommon.TEXT_HELPER_STAGE_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageSourceName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={6} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_SOURCE_ACTION_NAME}
          control={codePipeline.sourceStage.actionName}
          label={TextCommon.TEXT_ACTION_NAME}
          helperText={TextCommon.TEXT_HELPER_ACTION_NAME}
          required
          onChangeValue={(value: string): void => ActionHandler.setStageSourceActionName(dispatch, value)}
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_OWNER}
          control={codePipeline.sourceStage.githubOwner}
          label={TextCommon.TEXT_GITHUB_OWNER}
          helperText={TextCommon.TEXT_HELPER_GITHUB_OWNER}
          required
          onChangeValue={handleGithubOwner}
          onUnFocusValue={handleGithubOwner}
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_REPO}
          control={codePipeline.sourceStage.githubRepo}
          label={TextCommon.TEXT_GITHUB_REPO}
          helperText={TextCommon.TEXT_HELPER_GITHUB_REPO}
          required
          onChangeValue={handleGithubRepo}
          onUnFocusValue={handleGithubRepo}
        />
      </Grid>
      <Grid item xs={12} sx={GLOBAL_MUI_SX.flexHorizontalGrowContentCenter}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_PIPELINE_STAGE_SOURCE_GITHUB_BRANCH}
          control={codePipeline.sourceStage.githubBranch}
          label={TextCommon.TEXT_GITHUB_BRANCH}
          helperText={TextCommon.TEXT_HELPER_GITHUB_BRANCH}
          required
          onChangeValue={handleGithubBranch}
          onUnFocusValue={handleGithubBranch}
        />
      </Grid>
    </Grid>
  );
};

export default StageSource;
