class MessageCommon {
  public static readonly MUST_BE_BETWEEN_CHARACTERS = `Must be from {0} to {1} characters`;
  public static readonly AT_LEAST_8_CHARACTERS = `At least 8 characters`;
  public static readonly AT_LEAST_1_LOWERCASE = `At least one lowercase letter (a-z)`;
  public static readonly AT_LEAST_1_UPPERCASE = `At least one uppercase letter (A-Z)`;
  public static readonly AT_LEAST_1_DIGIT = `At least one digit (0-9)`;
  public static readonly AT_LEAST_1_SPECIAL = `At least one special character (such as @$!%*?&)`;
  public static readonly MUST_BE_UUID_V4 = `Must be UUIDv4`;
  public static readonly MUST_BE_JSON = `Must be JSON`;
  public static readonly GITHUB_OWNER = `Alphanumeric characters and single hyphens (-)`;
  public static readonly GITHUB_OWNER_SPECIAL = `Cannot begin or end with a hyphen (-)`;
  public static readonly GITHUB_REPO = `Alphanumeric characters with no spaces and include: _-.`;
  public static readonly GENERAL_NAME = `Alphanumeric characters with no spaces and include: _+=,.@-`;
  public static readonly SECRETS_MANAGER_NAME = `Alphanumeric characters with no spaces and include: /_+=.@-`;
  public static readonly CODEPIPELINE_NAME_GENERAL = `Alphanumeric characters with no spaces and include: .@-_`;
  public static readonly GITHUB_NAME_GENERAL = `Alphanumeric characters with no spaces and include: ._-\\`;
  public static readonly BUCKET_NAME_CONSIST_ONLY = `Can consist only of lowercase letters, numbers, dots (.), and hyphens (-)`;
  public static readonly BUCKET_NAME_MUST_BEGIN_END = `Must begin and end with a letter or number`;
  public static readonly BUCKET_NAME_NOT_CONTAIN = `Must not contain two adjacent periods`;
  public static readonly BUCKET_NAME_NOT_IP_ADDR = `Must not be formatted as an IP address`;
  public static readonly BUCKET_NAME_NOT_START_SPECIFIED = `Must not start with the prefix xn--`;
  public static readonly BUCKET_NAME_NOT_END_SPECIFIED = `Must not end with the suffix -s3alias or --ol-s3`;
  public static readonly CODEBUILD_NAME_GENERAL = `Alphanumeric characters with no spaces and include: underscore (_) and hyphens (-)`;
  public static readonly STACK_NAME_GENERAL = `Only alphanumeric characters with hyphens (-) and no spaces`;
  public static readonly YAML_JSON_FILE = `Must be YAML / JSON file extension (.yaml | .yml | .json)`;
  public static readonly YAML_FILE = `Must be YAML file extension (.yaml | .yml)`;
  public static readonly COPY_SUCCESSFUL = `Copy to Clipboard successfully!`;
  public static readonly DOWNLOAD_SUCCESSFUL = `Download {} successfully!`;
  public static readonly NOT_FOUND_ACTION = `Not Found Action`;
  public static readonly NOT_EMPTY = `Must not be empty`;
  public static readonly TLL_NOT_NEGATIVE = `Time to live cannot be negative`;
  public static readonly TLL_EXCEED_A_YEAR = `Time to live cannot be larger than 31536000 (seconds are in a year)`;
  public static readonly USERNAME_CONSIST_ONLY = `Can consist only of letter, number, underscore (_) and hyphens (-)`;
  public static readonly DOMAIN_NAME_CONSIST_ONLY = `Can consist only of lowercase letter hyphens (-) and dot(.)`;
  public static readonly ONLY_UPPERCASE_LETTER_NUMBER = `Can consist only of uppercase letter and number`;
  public static readonly MUST_START_WITH_LETTER = `Must start with an alphabetic character`;
}

export default MessageCommon;
