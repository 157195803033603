import { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAppContext, useAppDispatch } from '@Hooks';
import { Notification } from '@Components';
import { AppAction } from '@Stores';
import { DefaultCommon } from '@Commons';

import appRouter, { IRouter } from './routers';
import { GeneratorUtils } from './utils';

const App = (): React.ReactElement => {
  const { notify } = useAppContext();
  const appDispatch = useAppDispatch();

  const handleCloseNoti = useCallback(() => {
    AppAction.close(appDispatch);
  }, [notify.isOpen]);

  const renderRoute = (routers: IRouter[]): React.ReactElement[] => {
    return routers.map((eachRoute: IRouter) => {
      if (eachRoute.children === undefined || eachRoute.children.length === 0) {
        return (
          <Route
            path={eachRoute.path}
            key={GeneratorUtils.newUUIDv4()}
            index={!!eachRoute.index}
            element={eachRoute.element()}
          />
        );
      }
      return (
        <Route
          path={eachRoute.path}
          key={GeneratorUtils.newUUIDv4()}
          index={!!eachRoute.index}
          element={eachRoute.element()}
        >
          {renderRoute(eachRoute.children)}
        </Route>
      );
    });
  };

  return (
    <>
      <Routes>{renderRoute(appRouter)}</Routes>
      <div>
        {notify.isOpen && (
          <Notification
            open={notify.isOpen}
            autoHideDuration={DefaultCommon.APP_AUTO_HIDE_DURATION_MILLIS}
            message={notify.message || DefaultCommon.VAR_EMPTY_STRING}
            type={notify.type}
            onClose={handleCloseNoti}
          />
        )}
      </div>
    </>
  );
};

export default App;
