import { useEffect, useState } from 'react';

import { IHorizontalItem } from '@Components';
import { DefaultCommon } from '@Commons';

interface IStepperControl {
  handleNextAction: () => void;
  handleBackAction: () => void;
  handleFinishAction: (value: boolean) => void;
  handleResetAction: () => void;
  isFirst: boolean;
  isLast: boolean;
  isFinish: boolean;
}

export interface IStepperControlOutput {
  steps: IHorizontalItem[];
  activeStep: number;
  action: IStepperControl;
}

export interface IStepperControlProps {
  data: IHorizontalItem[];
  activeDefault?: number;
}

const useStepperControl = (props: IStepperControlProps): IStepperControlOutput => {
  const [steps, setSteps] = useState<IHorizontalItem[]>(props.data);
  const [activeStep, setActiveStep] = useState<number>(props.activeDefault || DefaultCommon.APP_ACTIVE_STEP_DEFAULT);
  const [finish, setFinish] = useState<boolean>(false);

  useEffect(() => {
    if (!props.activeDefault) {
      handleResetAction();
    }
  }, []);

  const handleNextAction = (): void => {
    if (activeStep < steps.length - DefaultCommon.APP_PER_STEP_UNIT) {
      setSteps(_updatedNextStep());
      setActiveStep(activeStep + DefaultCommon.APP_PER_STEP_UNIT);
    }
  };

  const handleBackAction = (): void => {
    if (activeStep > DefaultCommon.APP_ACTIVE_STEP_DEFAULT) {
      setSteps(_updatedBackStep());
      setActiveStep(activeStep - DefaultCommon.APP_PER_STEP_UNIT);
    }
  };

  const handleFinishAction = (value: boolean): void => {
    if (value) {
      setSteps(_updatedNextStep());
    }
    setFinish(value);
  };

  const handleResetAction = (): void => {
    const resetSteps = steps.map((step: IHorizontalItem) => ({ ...step, completed: false }));
    setSteps(resetSteps);
    setFinish(false);
    setActiveStep(DefaultCommon.APP_ACTIVE_STEP_DEFAULT);
  };

  const _updatedNextStep = (): IHorizontalItem[] => {
    steps[activeStep].completed = true;
    return [...steps];
  };

  const _updatedBackStep = (): IHorizontalItem[] => {
    if (activeStep === steps.length - DefaultCommon.APP_PER_STEP_UNIT) {
      steps[activeStep - DefaultCommon.APP_PER_STEP_UNIT].completed = false;
      steps[activeStep].completed = false;
    } else if (activeStep > DefaultCommon.APP_PER_STEP_UNIT) {
      steps[activeStep - DefaultCommon.APP_PER_STEP_UNIT].completed = false;
    } else {
      steps[DefaultCommon.APP_PER_STEP_UNIT].completed = false;
      steps[DefaultCommon.APP_ACTIVE_STEP_DEFAULT].completed = false;
    }
    return [...steps];
  };

  const isFirst = activeStep === DefaultCommon.APP_ACTIVE_STEP_DEFAULT;
  const isLast = activeStep === steps.length - DefaultCommon.APP_PER_STEP_UNIT;

  return {
    steps,
    activeStep,
    action: {
      handleNextAction,
      handleBackAction,
      handleFinishAction,
      handleResetAction,
      isFirst,
      isLast,
      isFinish: finish,
    },
  };
};

export default useStepperControl;
