export const S3_STATIC_HOSTING_CFN_TEMPLATE = `AWSTemplateFormatVersion: 2010-09-09
Description: 
  Static hosting S3 and custom domain.
  Remember setting your CLI config region to  US East (N. Virginia) - us-east-1
Parameters:  
  S3BucketName:
    Description: Bucket Name , remember unique name
    Default: {staticHosting.s3BucketName.value}
    Type: String
  Username:
    Description: User name use to login
    Default: {staticHosting.username.value}
    Type: String
  Password:
    NoEcho: true
    Description: Password use to login
    Default: {staticHosting.password.value}
    Type: String
  TimeToLive:
    Description: Time (in seconds) the content is cache
    Default: {staticHosting.timeToLive.value}
    Type: Number
  DomainName:
    Description: your custom Domain Name
    Default: {staticHosting.domainName.value}
    Type: String
  HostZoneId:
    Description: host zone ID in route53
    Default: {staticHosting.hostZoneId.value}
    Type: String
Conditions:
  DomainNameExists: !Not [!Equals [!Ref DomainName, ""]]
Resources:
  CachePolicy:
    Type: "AWS::CloudFront::CachePolicy"
    Properties:
      CachePolicyConfig:
        Name: !Sub "\${{S3BucketName}}-CachePolicy"
        Comment: !Sub "Create for S3 Static hosting \${{S3BucketName}}"
        DefaultTTL: !Sub \${{TimeToLive}}
        MaxTTL: 31536000
        MinTTL: 1
        ParametersInCacheKeyAndForwardedToOrigin:
          CookiesConfig:
            CookieBehavior: "none"
          HeadersConfig:
            HeaderBehavior: "none"
          QueryStringsConfig:
            QueryStringBehavior: "none"
          EnableAcceptEncodingBrotli: true
          EnableAcceptEncodingGzip: true

  OriginAccessIdentityTemplate:
    Type: "AWS::CloudFront::CloudFrontOriginAccessIdentity"
    Properties:
      CloudFrontOriginAccessIdentityConfig:
        Comment: "Origin access identity to BucketCloudFront"

  BucketCloudFront:
    Type: "AWS::S3::Bucket"
    Properties:
      BucketName: !Sub "\${{S3BucketName}}"

  BucketPolicy:
    DependsOn: 
      - BucketCloudFront
      - OriginAccessIdentityTemplate
    Type: "AWS::S3::BucketPolicy"
    Properties:
      Bucket: !Ref BucketCloudFront
      PolicyDocument:
        Version: "2012-10-17"
        Statement:
        - Sid: "CloudFrontAccessPolicy"
          Effect: "Allow"
          Principal:
            AWS: !Sub "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity \${{OriginAccessIdentityTemplate}}"
          Action: "s3:GetObject"
          Resource: !Join 
              - ""
              - - "arn:aws:s3:::"
                - !Ref BucketCloudFront
                - "/*"

  IAMRoleForLambda:
    Type: "AWS::IAM::Role"
    Properties:
      RoleName: !Sub "role-for-lambda-access-\${{S3BucketName}}"
      AssumeRolePolicyDocument:
        Version: "2012-10-17"
        Statement:
          - Effect: "Allow"
            Principal:
              Service: 
                - "lambda.amazonaws.com"
                - "edgelambda.amazonaws.com"
            Action: "sts:AssumeRole"
      Path: "/"
      ManagedPolicyArns:
        - "arn:aws:iam::aws:policy/AWSLambda_FullAccess"

  LambdaFunction:
    DependsOn: 
      - IAMRoleForLambda
    Type: AWS::Lambda::Function
    Properties: 
      FunctionName: !Sub "Authorize-for-\${{BucketCloudFront}}"
      Runtime: nodejs20.x
      Architectures: 
      - x86_64
      Handler: index.handler
      Role: !GetAtt "IAMRoleForLambda.Arn"
      Code:
        ZipFile: !Sub |
          exports.handler = (event, context, callback) => {
              const request = event.Records[0].cf.request;
              const headers = request.headers;
              
              const authUser = '\${{Username}}';
              const authPass = '\${{Password}}';

              const authString = 'Basic ' + new Buffer(authUser + ':' + authPass).toString('base64');

              if (typeof headers.authorization == 'undefined' || headers.authorization[0].value != authString) {
                  const body = 'Unauthorized';
                  const response = {
                      status: '401',
                      statusDescription: 'Unauthorized',
                      body: body,
                      headers: {
                          'www-authenticate': [{{key: 'WWW-Authenticate', value:'Basic'}}]
                      },
                  };
                  callback(null, response);
              }

              callback(null, request);
          }
      Description: Invoke a function during stack creation.
      
  LambdaFunctionVersion:
    DependsOn: 
      - LambdaFunction
    Type: AWS::Lambda::Version
    Properties:
      FunctionName: !Ref LambdaFunction

  LambdaFunctionPermission:
    DependsOn: 
      - LambdaFunction
    Type: AWS::Lambda::Permission
    Properties:
      Action: lambda:GetFunction
      FunctionName: !GetAtt LambdaFunction.Arn
      Principal: cloudfront.amazonaws.com

  ACMCertificate:
    Type: AWS::CertificateManager::Certificate
    Properties:
      DomainName: !Sub '\${{DomainName}}'
      ValidationMethod: DNS
      DomainValidationOptions:
        - DomainName: !Sub '\${{DomainName}}'
          HostedZoneId: !Sub \${{HostZoneId}}
    Condition: DomainNameExists

  DistributionTemplate:
    DependsOn: 
    - BucketCloudFront
    - CachePolicy
    - LambdaFunctionVersion
    - LambdaFunction
    Type: AWS::CloudFront::Distribution
    Properties:
      DistributionConfig:
        Origins:
          - DomainName: !GetAtt "BucketCloudFront.RegionalDomainName"
            Id: !GetAtt "BucketCloudFront.RegionalDomainName"
            S3OriginConfig:
              OriginAccessIdentity: !Sub "origin-access-identity/cloudfront/\${{OriginAccessIdentityTemplate}}"
        Enabled: true
        DefaultRootObject: index.html
        DefaultCacheBehavior:
          Compress: true
          ViewerProtocolPolicy: redirect-to-https
          AllowedMethods:
            - GET
            - HEAD
          TargetOriginId: !GetAtt "BucketCloudFront.RegionalDomainName"
          ForwardedValues:
            QueryString: false
            Cookies:
              Forward: none
          CachePolicyId: !Ref CachePolicy
          LambdaFunctionAssociations: 
            - EventType: viewer-request
              IncludeBody: false
              LambdaFunctionARN: !Ref LambdaFunctionVersion
        Aliases :
          - !If [ DomainNameExists, !Sub '\${{DomainName}}', !Ref 'AWS::NoValue' ]
        CNAMEs: 
          - !If [ DomainNameExists, !Sub '\${{DomainName}}', !Ref 'AWS::NoValue' ]
        ViewerCertificate:
          !If
            - DomainNameExists
            - AcmCertificateArn: !If [ DomainNameExists, !Ref ACMCertificate, !Ref 'AWS::NoValue' ]
              SslSupportMethod: sni-only
              MinimumProtocolVersion: TLSv1.2_2021
            - !Ref AWS::NoValue
        HttpVersion: "http2"
        IPV6Enabled: true

  Route53RecordSet:
    DependsOn: 
      - DistributionTemplate
    Type: AWS::Route53::RecordSet
    Properties:
      HostedZoneId: !Sub '\${{HostZoneId}}'
      Name: !Sub '\${{DomainName}}'
      Type: A
      SetIdentifier: !Sub '\${{DomainName}}-identifier'
      AliasTarget:
        DNSName: !GetAtt DistributionTemplate.DomainName
        EvaluateTargetHealth: false
        HostedZoneId: Z2FDTNDATAQYW2
      Region: us-east-1
    Condition: DomainNameExists

Outputs:
  StackName:
    Description: "Stack name"
    Value: !Sub "\${{AWS::StackName}}"
  Region:
    Description: "The Region of all resource"
    Value: !Ref "AWS::Region"
  CloudfrontDefaultDomain:
    Description: "Default domain name of cloudfront distribution"
    Value: !GetAtt DistributionTemplate.DomainName
  YourCustomDomain:
    Description: "Your custom domain name"
    Value: !If
      - DomainNameExists
      - !Sub "\${{DomainName}}"
      - ""
`;

export default S3_STATIC_HOSTING_CFN_TEMPLATE;
