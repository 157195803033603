class DefaultCommon {
  public static readonly CONST_SOURCE = 'Source';
  public static readonly CONST_FETCH_SOURCE = 'FetchSource';
  public static readonly CONST_BUILD_SPEC = 'buildspec.yaml';
  public static readonly CONST_BUILD = 'Build';
  public static readonly CONST_BUILD_SERVERLESS = 'BuildServerless';
  public static readonly CONST_DEPLOY = 'Deploy';
  public static readonly CONST_DEPLOYMENT = 'Deployment';
  public static readonly CONST_STACK_TEMPLATE = 'serverless-export.yaml';
  public static readonly CONST_CREATE_CHANGE_SET = 'CreateChangeSet';
  public static readonly CONST_APPLY_CHANGE_SET = 'ApplyChangeSet';
  public static readonly CONST_S3_ACCESS_CONTROL_USERNAME = 'accretech';
  public static readonly CONST_S3_ACCESS_CONTROL_PASSWORD = 'Accretech@2023';
  public static readonly CONST_S3_ACCESS_CONTROL_TTL_IN_SECONDS = 86400;
  public static readonly CONST_CODE_PIPELINE_PATH = '/';
  public static readonly CONST_STATIC_HOSTING_PATH = '/static-hosting';

  public static readonly VAR_EMPTY_STRING = ``;
  public static readonly VAR_EMPTY_OBJECT = {};
  public static readonly VAR_ZERO = 0;
  public static readonly VAR_UUID4_VERSION = 4;
  public static readonly VAR_CHARACTER_HYPHENS = `-`;
  public static readonly VAR_CHARACTER_SPACE = ` `;
  public static readonly VAR_CHARACTER_ASTERISK = `*`;
  public static readonly VAR_CHARACTER_DOTS = `.`;

  public static readonly INPUT_1_LENGTH = 1;
  public static readonly INPUT_2_LENGTH = 2;
  public static readonly INPUT_3_LENGTH = 3;
  public static readonly INPUT_32_LENGTH = 32;
  public static readonly INPUT_39_LENGTH = 39;
  public static readonly INPUT_63_LENGTH = 63;
  public static readonly INPUT_64_LENGTH = 64;
  public static readonly INPUT_100_LENGTH = 100;
  public static readonly INPUT_128_LENGTH = 128;
  public static readonly INPUT_250_LENGTH = 250;
  public static readonly INPUT_254_LENGTH = 254;
  public static readonly INPUT_255_LENGTH = 255;
  public static readonly INPUT_256_LENGTH = 256;

  public static readonly VAR_A_YEAR_IN_SECONDS = 31536000;

  public static readonly MIME_TYPE_YAML = 'text/x-yaml';
  public static readonly MIME_TYPE_JSON = 'application/json';
  public static readonly EXPORT_CFN_YAML_FILENAME = 'codepipeline.cfn.yaml';
  public static readonly EXPORT_CFN_JSON_FILENAME = 'codepipeline.cfn.json';
  public static readonly EXPORT_CFN_STATIC_HOSTING_YAML_FILENAME = 's3-static-hosting.cfn.yaml';
  public static readonly EXPORT_CFN_STATIC_HOSTING_JSON_FILENAME = 's3-static-hosting.cfn.json';

  public static readonly APP_AUTO_HIDE_DURATION_MILLIS = 5000;
  public static readonly APP_ACTIVE_STEP_DEFAULT = 0;
  public static readonly APP_PER_STEP_UNIT = 1;
}

export default DefaultCommon;
