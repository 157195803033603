import { MsgCommon } from '@Commons';
/* eslint-disable no-case-declarations */
import { DefaultCommon, TextCommon } from '@Commons';
import { IStaticHostingSettingData, IFieldInput, IStaticHosting, IValidStep } from '@Type';
import { EAction, EStaticHostingAction } from './action';
export const NONE_ERROR = {
  isError: false,
  errorMsg: TextCommon.TEXT_EMPTY_STRING,
};

interface IStatcHostingKey {
  s3BucketName: keyof IStaticHosting;
  username: keyof IStaticHosting;
  password: keyof IStaticHosting;
  timeToLive: keyof IStaticHosting;
  domainName: keyof IStaticHosting;
  hostZoneId: keyof IStaticHosting;
  customDomain: keyof IStaticHosting;
}

const statcHostingKey: IStatcHostingKey = {
  s3BucketName: 's3BucketName',
  username: 'username',
  password: 'password',
  timeToLive: 'timeToLive',
  domainName: 'domainName',
  hostZoneId: 'hostZoneId',
  customDomain: 'customDomain',
};

export const INITIAL_SETTING_DATA: IStaticHostingSettingData = {
  isValidSteps: {},
  staticHosting: {
    s3BucketName: { value: DefaultCommon.VAR_EMPTY_STRING },
    username: { value: DefaultCommon.CONST_S3_ACCESS_CONTROL_USERNAME },
    password: { value: DefaultCommon.CONST_S3_ACCESS_CONTROL_PASSWORD },
    timeToLive: { value: DefaultCommon.CONST_S3_ACCESS_CONTROL_TTL_IN_SECONDS },
    domainName: { value: DefaultCommon.VAR_EMPTY_STRING },
    hostZoneId: { value: DefaultCommon.VAR_EMPTY_STRING },
    customDomain: { value: false },
  },
};

const _mappingStaticHosting = <T>(
  prevState: IStaticHostingSettingData,
  action: EStaticHostingAction<T>,
  property: keyof IStaticHosting,
): IStaticHostingSettingData => {
  return {
    ...prevState,
    staticHosting: {
      ...prevState.staticHosting,
      [property]: { ...(action.payload as IFieldInput<T>) },
    },
  };
};

const staticHostingReducer = (
  prevState: IStaticHostingSettingData,
  action: EStaticHostingAction<any>,
): IStaticHostingSettingData => {
  switch (action.type) {
    case EAction.SET_STATIC_HOSTING_BUCKET:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.s3BucketName);

    case EAction.SET_STATIC_HOSTING_USERNAME:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.username);

    case EAction.SET_STATIC_HOSTING_PASSWORD:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.password);

    case EAction.SET_STATIC_HOSTING_TTL:
      return _mappingStaticHosting<number>(prevState, action, statcHostingKey.timeToLive);

    case EAction.SET_STATIC_HOSTING_DOMAIN_NAME:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.domainName);

    case EAction.SET_STATIC_HOSTING_HOST_ZONE_ID:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.hostZoneId);

    case EAction.SET_STATIC_HOSTING_OPTION:
      return _mappingStaticHosting<string>(prevState, action, statcHostingKey.customDomain);

    case EAction.SET_VALID_STEPS:
      return {
        ...prevState,
        isValidSteps: {
          ...prevState.isValidSteps,
          ...(action.payload as IValidStep),
        },
      };
    case EAction.RESET_PAGE:
      return INITIAL_SETTING_DATA;

    default:
      throw Error(MsgCommon.NOT_FOUND_ACTION);
  }
};

export default staticHostingReducer;
