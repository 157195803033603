import { forwardRef, memo } from 'react';
import { Snackbar } from 'nxg-ui-wrapper';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';

import { GLOBAL_MUI_SX } from '@Global';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface INotificationProps {
  open: boolean;
  autoHideDuration: number;
  message: string;
  type: AlertColor;
  onClose: (event: object) => void;
}

const Notification = ({ open, message, autoHideDuration, type, onClose }: INotificationProps): React.ReactElement => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={GLOBAL_MUI_SX.anchorOriginTopCenter}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Alert onClose={onClose} severity={type} sx={GLOBAL_MUI_SX.w100}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default memo(Notification);
