/* eslint-disable @typescript-eslint/ban-types */
import { Dispatch } from 'react';
import { StringUtils, ValidatorUtils } from '@Utils';
import { MsgCommon, DefaultCommon, RegexCommon } from '@Commons';
import { IFieldInput, IValidStep } from '@Type';
import { NONE_ERROR } from './reducer';

export interface EStaticHostingAction<T> {
  type: EAction;
  payload: IFieldInput<T> | IValidStep;
}

type TPayloadType = string | number | object | boolean;

export enum EAction {
  SET_STATIC_HOSTING_BUCKET = 'SET_STATIC_HOSTING_BUCKET',
  SET_STATIC_HOSTING_USERNAME = 'SET_STATIC_HOSTING_USERNAME',
  SET_STATIC_HOSTING_PASSWORD = 'SET_STATIC_HOSTING_PASSWORD',
  SET_STATIC_HOSTING_TTL = 'SET_STATIC_HOSTING_TTL',
  SET_STATIC_HOSTING_DOMAIN_NAME = 'SET_STATIC_HOSTING_DOMAIN_NAME',
  SET_STATIC_HOSTING_HOST_ZONE_ID = 'SET_STATIC_HOSTING_HOST_ZONE_ID',
  SET_STATIC_HOSTING_OPTION = 'SET_STATIC_HOSTING_OPTION',
  RESET_PAGE = 'RESET_PAGE',
  SET_VALID_STEPS = 'SET_VALID_STEPS',
}

export class ActionHandler {
  static setStaticHostingBucketName(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_BUCKET;
    const value: string = input.trim();

    const isError = [
      ValidatorUtils.isEmptyString(value),
      !ValidatorUtils.isMatchRegex(value, RegexCommon.S3_STATIC_HOSTING_BUCKET_NAME),
      value.includes(DefaultCommon.VAR_CHARACTER_DOTS.repeat(2)),
    ];
    if (isError.some(el => el === true)) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_3_LENGTH,
          DefaultCommon.INPUT_63_LENGTH,
        ),
        MsgCommon.BUCKET_NAME_CONSIST_ONLY,
        MsgCommon.BUCKET_NAME_MUST_BEGIN_END,
        MsgCommon.BUCKET_NAME_NOT_CONTAIN,
        MsgCommon.BUCKET_NAME_NOT_START_SPECIFIED,
        MsgCommon.BUCKET_NAME_NOT_END_SPECIFIED,
        MsgCommon.BUCKET_NAME_NOT_IP_ADDR,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStaticHostingUsername(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_USERNAME;
    const value: string = input.trim();
    const isError = [ValidatorUtils.isEmptyString(value), !ValidatorUtils.isMatchRegex(value, RegexCommon.USERNAME)];
    if (isError.some(el => el === true)) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_3_LENGTH,
          DefaultCommon.INPUT_64_LENGTH,
        ),
        MsgCommon.USERNAME_CONSIST_ONLY,
        MsgCommon.BUCKET_NAME_MUST_BEGIN_END,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStaticHostingPassword(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_PASSWORD;
    const value: string = input.trim();
    const isError = [ValidatorUtils.isEmptyString(value), !ValidatorUtils.isMatchRegex(value, RegexCommon.PASSWORD)];
    if (isError.some(el => el === true)) {
      const errorMsg = [
        MsgCommon.AT_LEAST_8_CHARACTERS,
        MsgCommon.AT_LEAST_1_LOWERCASE,
        MsgCommon.AT_LEAST_1_UPPERCASE,
        MsgCommon.AT_LEAST_1_DIGIT,
        MsgCommon.AT_LEAST_1_SPECIAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStaticHostingTTL(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_TTL;
    const value: string = input.trim();
    const valueNum: number = +value;
    const isError = [
      ValidatorUtils.isEmptyString(value),
      !ValidatorUtils.isMatchRegex(value, RegexCommon.TIME_TO_LIVE),
      ValidatorUtils.isLessThanZero(valueNum),
      ValidatorUtils.isMoreThanAYear(valueNum),
    ];
    if (isError.some(el => el === true)) {
      const errorMsg = [MsgCommon.AT_LEAST_1_DIGIT, MsgCommon.TLL_NOT_NEGATIVE, MsgCommon.TLL_EXCEED_A_YEAR];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStaticHostingDomainName(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_DOMAIN_NAME;
    const value: string = input.trim();
    const isError = [
      ValidatorUtils.isEmptyString(value),
      !ValidatorUtils.isMatchRegex(value, RegexCommon.HOST_ZONE_NAME),
    ];
    if (isError.some(el => el === true)) {
      const errorMsg = [MsgCommon.NOT_EMPTY, MsgCommon.DOMAIN_NAME_CONSIST_ONLY, MsgCommon.BUCKET_NAME_MUST_BEGIN_END];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStaticHostingHostZoneId(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_HOST_ZONE_ID;
    const value: string = input.trim();
    const isError = [
      ValidatorUtils.isEmptyString(value),
      !ValidatorUtils.isMatchRegex(value, RegexCommon.HOST_ZONE_ID),
    ];
    if (isError.some(el => el === true)) {
      const errorMsg = [MsgCommon.NOT_EMPTY, MsgCommon.ONLY_UPPERCASE_LETTER_NUMBER];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setHostingOption(dispatch: Dispatch<EStaticHostingAction<string>>, input: string): void {
    const type = EAction.SET_STATIC_HOSTING_OPTION;
    const value: string = input;
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static reset(dispatch: Dispatch<EStaticHostingAction<string>>): void {
    dispatch({
      type: EAction.RESET_PAGE,
      payload: {},
    });
  }

  private static _generatePayloadError(
    type: EAction,
    value: TPayloadType,
    errorMsg: string[],
  ): EStaticHostingAction<any> {
    return {
      type,
      payload: {
        value,
        isError: true,
        errorMsg,
      },
    };
  }

  private static _generatePayload(type: EAction, value: TPayloadType): EStaticHostingAction<any> {
    return {
      type,
      payload: {
        value,
        ...NONE_ERROR,
      },
    };
  }
}
