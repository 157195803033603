/* eslint-disable no-useless-escape */
class RegexCommon {
  static readonly ROLE_NAME = /^$|^([\w\+\=\,\.\@\-]{1,64})$/;
  static readonly BUCKET_NAME =
    /^$|(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})\.){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$|.+--ol-s3$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$/;
  static readonly CODEBUILD_NAME = /^$|(^[A-Za-z0-9]){1}(([A-Za-z0-9\-\_]){1,254})$/;
  static readonly YAML_JSON_FILE = /^[A-Za-z0-9\-\_\.]{1,250}(.yaml|.yml|.json)$/;
  static readonly YAML_FILE = /^[A-Za-z0-9\-\_\.]{1,250}(.yaml|.yml)$/;
  static readonly GENERAL_NAME = /^[A-Za-z0-9\.\@\-\_]{1,100}$/;
  static readonly GITHUB_BRANCH = /^.{1,250}$/;
  static readonly GITHUB_REPO = /^[A-Za-z0-9\_\-\.]{1,100}$/;
  static readonly GITHUB_OWNER = /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/;
  static readonly STACK_NAME = /^[A-Za-z][A-Za-z0-9\-]{0,127}$/;
  static readonly CODEPIPELINE_NAME = /^$|^[A-Za-z0-9\.\@\-_]{1,100}$/;
  static readonly SECRETS_MANAGER_NAME = /^[A-Za-z0-9\/\_\+\=\.\@\-]{1,256}$/;
  static readonly CHARACTER_TAB = /\t/g;
  static readonly USERNAME = /^[A-Za-z0-9_-]{3,64}$/;
  static readonly PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;
  static readonly TIME_TO_LIVE = /^[0-9]+$/;
  static readonly HOST_ZONE_NAME = /^[a-z0-9][-\.a-z0-9]*[a-z0-9]$/;
  static readonly HOST_ZONE_ID = /^[A-Z0-9]+$/;
  static readonly S3_STATIC_HOSTING_BUCKET_NAME =
    /^(?!$)(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})\.){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$|.+--ol-s3$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$/;
}
export default RegexCommon;
