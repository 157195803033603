import { useMemo } from 'react';
import { TextField } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel } from 'nxg-ui-wrapper';

import { DefaultCommon } from '@Commons';
import { SelectWithExtend, SelectWithExtendItem } from '@Components';
import { IFieldInput } from '@Type';

export enum EInputType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
}

export interface IInputControlProps<T> {
  control: IFieldInput<T>;
  label: string;
  required?: boolean;
  onChange?: (event: object) => void;
  onChangeValue?: (value: T) => void;
  onFocusValue?: (value: T) => void;
  onUnFocusValue?: (value: T) => void;
  inputProps?: Partial<InputProps>;
  type?: EInputType;
  children?: React.ReactNode;
  SelectItems?: SelectWithExtendItem[];
  helperText?: string;
}

const InputControl = (props: IInputControlProps<any>): React.ReactElement => {
  const handleChange = (event: SelectChangeEvent<unknown> | React.ChangeEvent<any>): void => {
    props.onChange && props.onChange(event);
    props.onChangeValue && props.onChangeValue(event?.target?.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChange && props.onChange(event);
    props.onChangeValue && props.onChangeValue(event?.target?.checked);
  };

  const handleFocus = (event: React.ChangeEvent<any>): void => {
    props.onFocusValue && props.onFocusValue(event?.target?.value);
  };

  const handleUnFocus = (event: React.ChangeEvent<any>): void => {
    props.onUnFocusValue && props.onUnFocusValue(event?.target?.value);
  };

  const mergeMessage = useMemo(() => {
    let msg: string = props.helperText || DefaultCommon.VAR_EMPTY_STRING;

    if (props.control.isError && props.control.errorMsg) {
      msg = props.control.errorMsg.join(DefaultCommon.VAR_CHARACTER_DOTS + DefaultCommon.VAR_CHARACTER_SPACE);
    }
    return msg;
  }, [props.control.isError]);

  switch (props.type) {
    case EInputType.CHECKBOX:
      return (
        <>
          <FormControlLabel
            data-cy={props['data-cy']}
            control={<Checkbox checked={props.control.value} onChange={handleCheckboxChange} />}
            label={props.label}
          />
          <FormHelperText>{mergeMessage}</FormHelperText>
        </>
      );
    case EInputType.SELECT:
      return (
        <FormControl fullWidth>
          <InputLabel required={props.required}>{props.label}</InputLabel>
          <SelectWithExtend
            data-cy={props['data-cy']}
            required={props.required}
            value={props.control.value}
            label={props.label}
            items={props.SelectItems || []}
            onChange={handleChange}
          />
          <FormHelperText>{mergeMessage}</FormHelperText>
        </FormControl>
      );

    default:
      return (
        <TextField
          data-cy={props['data-cy']}
          error={props.control.isError}
          value={props.control.value}
          autoComplete="off"
          fullWidth
          label={props.label}
          helperText={mergeMessage}
          required={props.required}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleUnFocus}
          InputProps={props.inputProps}
        ></TextField>
      );
  }
};

export default InputControl;
