import { useEffect, useState } from 'react';
import { Grid } from 'nxg-ui-wrapper';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { TextCommon } from '@Commons';
import { InputControl, EInputType } from '@Components';
import { IStaticHostingSettingData } from '@Type';
import useCodePipeline from '../hooks/useStaticHosting';
import { ActionHandler } from '../stores/action';
import { useStaticHostingDispatch } from '../hooks';
import { CodePipelineTest, S3StaticHostingTest } from '_Test';

const MUI_STYLE: SxProps<Theme> = {
  justifyContent: 'center',
  display: 'flex',
};

const StaticConfiguration = (): React.ReactElement => {
  const { staticHosting } = useCodePipeline() as IStaticHostingSettingData;
  const { username, password, s3BucketName, domainName, hostZoneId, customDomain, timeToLive } = staticHosting;
  const [isCustomDomain, setIsCustomDomain] = useState<boolean>(false);

  const dispatch = useStaticHostingDispatch();

  useEffect(() => {
    handelCustomDomain();
  }, [customDomain]);

  const handleBucketName = (value: string): void => ActionHandler.setStaticHostingBucketName(dispatch, value);
  const handleUsername = (value: string): void => ActionHandler.setStaticHostingUsername(dispatch, value);
  const handlePassword = (value: string): void => ActionHandler.setStaticHostingPassword(dispatch, value);
  const handleTTL = (value: string): void => ActionHandler.setStaticHostingTTL(dispatch, value);
  const handleDomainName = (value: string): void => ActionHandler.setStaticHostingDomainName(dispatch, value);
  const handleHostZoneId = (value: string): void => ActionHandler.setStaticHostingHostZoneId(dispatch, value);

  const handelCustomDomain = (): void => {
    if (customDomain.value === true) {
      setIsCustomDomain(true);
    } else {
      setIsCustomDomain(false);
    }
  };

  return (
    <Grid container flexGrow={1} spacing={4} mt={2}>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_BUCKET_NAME}
          control={s3BucketName}
          label={TextCommon.TEXT_BUCKET_NAME}
          helperText={TextCommon.TEXT_HELPER_BUCKET_NAME}
          required
          onChangeValue={handleBucketName}
          onUnFocusValue={handleBucketName}
        ></InputControl>
      </Grid>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_USERNAME}
          required
          control={username}
          label={TextCommon.TEXT_USERNAME}
          helperText={TextCommon.TEXT_HELPER_USERNAME}
          onChangeValue={handleUsername}
          onUnFocusValue={handleUsername}
        ></InputControl>
      </Grid>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_PASSWORD}
          required
          control={password}
          label={TextCommon.TEXT_PASSWORD}
          helperText={TextCommon.TEXT_HELPER_PASSWORD}
          onChangeValue={handlePassword}
          onUnFocusValue={handlePassword}
        ></InputControl>
      </Grid>
      <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
        <InputControl
          data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_TTL}
          required
          control={timeToLive}
          label={TextCommon.TEXT_TIME_TO_LIVE}
          helperText={TextCommon.TEXT_HELPER_TIME_TO_LIVE}
          onChangeValue={handleTTL}
          onUnFocusValue={handleTTL}
        ></InputControl>
      </Grid>
      <Grid item xs={12}>
        <InputControl
          data-cy={CodePipelineTest.FORM_CODE_BUILD_BUCKET_DELETION}
          type={EInputType.CHECKBOX}
          control={customDomain}
          label={TextCommon.TEXT_HOSTING_OPTION}
          helperText={TextCommon.TEXT_HELPER_OPTIONAL_HOSTING}
          onChangeValue={(value: string): void => ActionHandler.setHostingOption(dispatch, value)}
        ></InputControl>
      </Grid>
      {isCustomDomain && (
        <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
          <InputControl
            data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_DOMAIN_NAME}
            control={domainName}
            label={TextCommon.TEXT_DOMAIN_NAME}
            helperText={TextCommon.TEXT_HELPER_DOMAIN_NAME}
            required
            onChangeValue={handleDomainName}
            onUnFocusValue={handleDomainName}
          ></InputControl>
        </Grid>
      )}
      {isCustomDomain && (
        <Grid item xs={12} flexGrow={1} sx={MUI_STYLE}>
          <InputControl
            data-cy={S3StaticHostingTest.FORM_STATIC_HOSTING_HOST_ZONE_ID}
            required
            control={hostZoneId}
            label={TextCommon.TEXT_HOST_ZONE_ID}
            helperText={TextCommon.TEXT_HELPER_HOST_ZONE_ID}
            onChangeValue={handleHostZoneId}
            onUnFocusValue={handleHostZoneId}
          ></InputControl>
        </Grid>
      )}
    </Grid>
  );
};

export default StaticConfiguration;
