import { useEffect } from 'react';
import { Grid, Typography } from 'nxg-ui-wrapper';
import { SystemStyleObject } from '@mui/system';

import { TextCommon } from '@Commons';
import { EInputType, InputControl } from '@Components';
import { ICodePipelineSettingData } from '@Type';
import { GLOBAL_MUI_SX } from '@Global';
import { ValidatorUtils } from '@Utils';
import { useCodePipeline, useCodePipelineDispatch } from '../hooks';
import { ActionHandler, EAction } from '../stores/action';
import { CodePipelineTest } from '_Test';

const TYPO_STYLE: SystemStyleObject = { mb: 2, ...GLOBAL_MUI_SX.textCenter };

interface IPreparationResourceProps {
  onDenyNext: (value: boolean) => void;
}

const PreparationResource = ({ onDenyNext }: IPreparationResourceProps): React.ReactElement => {
  const { codeBuild, codePipeline } = useCodePipeline() as ICodePipelineSettingData;
  const dispatch = useCodePipelineDispatch();

  useEffect(() => {
    const cbRoleNameError = ValidatorUtils.isAllowWithOptional(codeBuild.roleName.isError);
    const cbBucketNameError = ValidatorUtils.isAllowWithOptional(codeBuild.bucketName.isError);
    const cpRoleNameError = ValidatorUtils.isAllowWithOptional(codePipeline.roleName.isError);
    const cpBucketNameError = ValidatorUtils.isAllowWithOptional(codePipeline.bucketName.isError);
    onDenyNext(cbRoleNameError || cbBucketNameError || cpRoleNameError || cpBucketNameError);
  }, [codeBuild.roleName, codeBuild.bucketName, codePipeline.roleName, codePipeline.bucketName]);

  return (
    <Grid container flexGrow={1} spacing={4} mt={2} component="form">
      <Grid item xs={6} flexGrow={1}>
        <Typography variant="h6" sx={TYPO_STYLE}>
          {TextCommon.TEXT_AWS_CODEBUILD}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_BUILD_ROLE_NAME}
              control={codeBuild.roleName}
              label={TextCommon.TEXT_ROLE_NAME}
              helperText={TextCommon.TEXT_HELPER_ROLE_NAME}
              onChangeValue={(value: string): void =>
                ActionHandler.setRoleName(dispatch, value, EAction.SET_CODEBUILD_ROLE_NAME)
              }
            ></InputControl>
          </Grid>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_BUILD_BUCKET_NAME}
              control={codeBuild.bucketName}
              label={TextCommon.TEXT_BUCKET_NAME}
              helperText={TextCommon.TEXT_HELPER_BUCKET_NAME}
              onChangeValue={(value: string): void =>
                ActionHandler.setBucketName(dispatch, value, EAction.SET_CODEBUILD_BUCKET_NAME)
              }
            ></InputControl>
          </Grid>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_BUILD_BUCKET_DELETION}
              type={EInputType.CHECKBOX}
              control={codeBuild.deletionPolicy}
              label={TextCommon.TEXT_DELETION_POLICY_S3_BUCKET}
              helperText={TextCommon.TEXT_HELPER_DELETION_POLICY}
              onChangeValue={(value: boolean): void =>
                ActionHandler.setDeletionPolicy(dispatch, value, EAction.SET_CODEBUILD_DELETE_POLICY)
              }
            ></InputControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} flexGrow={1}>
        <Typography variant="h6" sx={TYPO_STYLE}>
          {TextCommon.TEXT_AWS_CODEPIPELINE}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_PIPELINE_ROLE_NAME}
              control={codePipeline.roleName}
              label={TextCommon.TEXT_ROLE_NAME}
              helperText={TextCommon.TEXT_HELPER_ROLE_NAME}
              onChangeValue={(value: string): void =>
                ActionHandler.setRoleName(dispatch, value, EAction.SET_CODEPIPELINE_ROLE_NAME)
              }
            ></InputControl>
          </Grid>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_PIPELINE_BUCKET_NAME}
              control={codePipeline.bucketName}
              label={TextCommon.TEXT_BUCKET_NAME}
              helperText={TextCommon.TEXT_HELPER_BUCKET_NAME}
              onChangeValue={(value: string): void =>
                ActionHandler.setBucketName(dispatch, value, EAction.SET_CODEPIPELINE_BUCKET_NAME)
              }
            ></InputControl>
          </Grid>
          <Grid item xs={12}>
            <InputControl
              data-cy={CodePipelineTest.FORM_CODE_PIPELINE_BUCKET_DELETION}
              type={EInputType.CHECKBOX}
              control={codePipeline.deletionPolicy}
              label={TextCommon.TEXT_DELETION_POLICY_S3_BUCKET}
              helperText={TextCommon.TEXT_HELPER_DELETION_POLICY}
              onChangeValue={(value: boolean): void =>
                ActionHandler.setDeletionPolicy(dispatch, value, EAction.SET_CODEPIPELINE_DELETE_POLICY)
              }
            ></InputControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreparationResource;
