import { Dispatch, useContext } from 'react';

import { ECodepipelineAction } from '../stores/action';
import { CodePipelineDispatchContext } from '../stores/provider';

const useCodePipelineDispatch = (): Dispatch<ECodepipelineAction<any>> => {
  return useContext(CodePipelineDispatchContext) as Dispatch<ECodepipelineAction<any>>;
};

export default useCodePipelineDispatch;
