/* eslint-disable @typescript-eslint/ban-types */
import { Dispatch } from 'react';

import { StringUtils, ValidatorUtils } from '@Utils';
import { MsgCommon, DefaultCommon, RegexCommon } from '@Commons';
import { IFieldInput, IValidStep } from '@Type';
import { NONE_ERROR } from './reducer';

export interface ECodepipelineAction<T> {
  type: EAction;
  payload: IFieldInput<T> | IValidStep;
}

type TPayloadType = string | number | object | boolean;

export enum EAction {
  SET_SECRET_MANAGER_NAME = 'SET_SECRET_MANAGER_NAME',
  SET_SECRET_MANAGER_TOKEN_VERSION = 'SET_SECRET_MANAGER_TOKEN_VERSION',
  SET_CODEBUILD_NAME = 'SET_CODEBUILD_NAME',
  SET_CODEBUILD_ROLE_NAME = 'SET_CODEBUILD_ROLE_NAME',
  SET_CODEBUILD_BUCKET_NAME = 'SET_CODEBUILD_BUCKET_NAME',
  SET_CODEBUILD_DELETE_POLICY = 'SET_CODEBUILD_DELETE_POLICY',
  SET_CODEBUILD_BUILD_SPEC = 'SET_CODEBUILD_BUILD_SPEC',
  SET_CODEBUILD_COMPUTE_TYPE = 'SET_CODEPIPELINE_COMPUTE_TYPE',
  SET_CODEBUILD_IMAGE_TYPE = 'SET_CODEPIPELINE_IMAGE_TYPE',
  SET_CODEBUILD_CONTAINER_TYPE = 'SET_CODEPIPELINE_CONTAINER_TYPE',
  SET_CODEPIPELINE_NAME = 'SET_CODEPIPELINE_NAME',
  SET_CODEPIPELINE_ROLE_NAME = 'SET_CODEPIPELINE_ROLE_NAME',
  SET_CODEPIPELINE_BUCKET_NAME = 'SET_CODEPIPELINE_BUCKET_NAME',
  SET_CODEPIPELINE_DELETE_POLICY = 'SET_CODEPIPELINE_DELETE_POLICY',
  SET_CODEPIPELINE_SOURCE_NAME = 'SET_CODEPIPELINE_SOURCE_NAME',
  SET_CODEPIPELINE_SOURCE_ACTION_NAME = 'SET_CODEPIPELINE_SOURCE_ACTION_NAME',
  SET_CODEPIPELINE_SOURCE_GITHUB_OWNER = 'SET_CODEPIPELINE_SOURCE_GITHUB_OWNER',
  SET_CODEPIPELINE_SOURCE_GITHUB_REPO = 'SET_CODEPIPELINE_SOURCE_GITHUB_REPO',
  SET_CODEPIPELINE_SOURCE_GITHUB_BRANCH = 'SET_CODEPIPELINE_SOURCE_GITHUB_BRANCH',
  SET_CODEPIPELINE_BUILD_NAME = 'SET_CODEPIPELINE_BUILD_NAME',
  SET_CODEPIPELINE_BUILD_ACTION_NAME = 'SET_CODEPIPELINE_BUILD_ACTION_NAME',
  SET_CODEPIPELINE_DEPLOY_NAME = 'SET_CODEPIPELINE_DEPLOY_NAME',
  SET_CODEPIPELINE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME = 'SET_CODEPIPELINE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME',
  SET_CODEPIPELINE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME = 'SET_CODEPIPELINE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME',
  SET_CODEPIPELINE_DEPLOY_STACK_NAME = 'SET_CODEPIPELINE_DEPLOY_STACK_NAME',
  SET_CODEPIPELINE_DEPLOY_ACTION_MODE = 'SET_CODEPIPELINE_DEPLOY_ACTION_MODE',
  SET_CODEPIPELINE_DEPLOY_TEMP_PATH = 'SET_CODEPIPELINE_DEPLOY_TEMP_PATH',
  SET_CODEPIPELINE_DEPLOY_PARAMETER = 'SET_CODEPIPELINE_DEPLOY_PARAMETER',
  RESET = 'RESET',
  SET_VALID_STEPS = 'SET_VALID_STEPS',
}

export class ActionHandler {
  static setSecretManagerName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_SECRET_MANAGER_NAME;
    const value: string = input.trim();
    const isError =
      !ValidatorUtils.isValidLength(value) || !ValidatorUtils.isMatchRegex(value, RegexCommon.SECRETS_MANAGER_NAME);

    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_256_LENGTH,
        ),
        MsgCommon.SECRETS_MANAGER_NAME,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setSecretManagerTokenVersion(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_SECRET_MANAGER_TOKEN_VERSION;
    const value: string = input.trim();
    const isError = value?.length > DefaultCommon.VAR_ZERO && !ValidatorUtils.isUUIDv4(value);

    if (isError) {
      const errorMsg = [MsgCommon.MUST_BE_UUID_V4];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setRoleName(
    dispatch: Dispatch<ECodepipelineAction<string>>,
    input: string,
    type: EAction.SET_CODEPIPELINE_ROLE_NAME | EAction.SET_CODEBUILD_ROLE_NAME,
  ): void {
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.ROLE_NAME);

    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_64_LENGTH,
        ),
        MsgCommon.GENERAL_NAME,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setBucketName(
    dispatch: Dispatch<ECodepipelineAction<string>>,
    input: string,
    type: EAction.SET_CODEBUILD_BUCKET_NAME | EAction.SET_CODEPIPELINE_BUCKET_NAME,
  ): void {
    const value: string = input.trim();
    const isError =
      (!ValidatorUtils.isEmptyString(value) && !ValidatorUtils.isMatchRegex(value, RegexCommon.BUCKET_NAME)) ||
      value.includes(DefaultCommon.VAR_CHARACTER_DOTS.repeat(2));

    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_3_LENGTH,
          DefaultCommon.INPUT_63_LENGTH,
        ),
        MsgCommon.BUCKET_NAME_CONSIST_ONLY,
        MsgCommon.BUCKET_NAME_MUST_BEGIN_END,
        MsgCommon.BUCKET_NAME_NOT_CONTAIN,
        MsgCommon.BUCKET_NAME_NOT_START_SPECIFIED,
        MsgCommon.BUCKET_NAME_NOT_END_SPECIFIED,
        MsgCommon.BUCKET_NAME_NOT_IP_ADDR,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }

    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setDeletionPolicy(
    dispatch: Dispatch<ECodepipelineAction<boolean>>,
    value: boolean,
    type: EAction.SET_CODEBUILD_DELETE_POLICY | EAction.SET_CODEPIPELINE_DELETE_POLICY,
  ): void {
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static reset(dispatch: Dispatch<ECodepipelineAction<string>>): void {
    dispatch({
      type: EAction.RESET,
      payload: {},
    });
  }

  static setValidSteps(dispatch: Dispatch<ECodepipelineAction<string>>, step: number, value: boolean): void {
    dispatch({
      type: EAction.SET_VALID_STEPS,
      payload: {
        [step]: value,
      },
    });
  }

  static setCodebuildName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEBUILD_NAME;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.CODEBUILD_NAME);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_2_LENGTH,
          DefaultCommon.INPUT_255_LENGTH,
        ),
        MsgCommon.CODEBUILD_NAME_GENERAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setBuildSpec(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEBUILD_BUILD_SPEC;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.YAML_FILE);
    if (isError) {
      const errorMsg = [MsgCommon.YAML_FILE];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setComputeType(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEBUILD_COMPUTE_TYPE;
    const value: string = input.trim();
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setImageType(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEBUILD_IMAGE_TYPE;
    const value: string = input.trim();
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setContainerType(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEBUILD_CONTAINER_TYPE;
    const value: string = input.trim();
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setCodePipelineName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_NAME;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.CODEPIPELINE_NAME);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_100_LENGTH,
        ),
        MsgCommon.CODEPIPELINE_NAME_GENERAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageSourceName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_SOURCE_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageSourceActionName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_SOURCE_ACTION_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageSourceOwner(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_SOURCE_GITHUB_OWNER;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.GITHUB_OWNER);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_39_LENGTH,
        ),
        MsgCommon.GITHUB_OWNER,
        MsgCommon.GITHUB_OWNER_SPECIAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageSourceRepo(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_SOURCE_GITHUB_REPO;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.GITHUB_REPO);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_100_LENGTH,
        ),
        MsgCommon.GITHUB_REPO,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageSourceBranch(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_SOURCE_GITHUB_BRANCH;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.GITHUB_BRANCH);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_250_LENGTH,
        ),
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageBuildName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_BUILD_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageBuildActionName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_BUILD_ACTION_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployCreateChangeSetName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_CREATE_CHANGE_SET_ACTION_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployApplyChangeSetName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_APPLY_CHANGE_SET_ACTION_NAME;
    const value: string = input.trim();
    const isError = ActionHandler._verifyAndSyncFieldWithGeneralName(dispatch, value, type);
    if (isError) {
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployStackName(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_STACK_NAME;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.STACK_NAME);

    if (isError) {
      const errorMsg = [
        MsgCommon.MUST_START_WITH_LETTER,
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_128_LENGTH,
        ),
        MsgCommon.STACK_NAME_GENERAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployActionMode(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_ACTION_MODE;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.GENERAL_NAME);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_100_LENGTH,
        ),
        MsgCommon.GENERAL_NAME,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployTemplatePath(dispatch: Dispatch<ECodepipelineAction<string>>, input: string): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_TEMP_PATH;
    const value: string = input.trim();
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.YAML_JSON_FILE);
    if (isError) {
      const errorMsg = [MsgCommon.YAML_JSON_FILE];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
      return;
    }
    dispatch(ActionHandler._generatePayload(type, value));
  }

  static setStageDeployParameter(dispatch: Dispatch<ECodepipelineAction<object>>, value: object): void {
    const type = EAction.SET_CODEPIPELINE_DEPLOY_PARAMETER;

    dispatch(ActionHandler._generatePayload(type, value));
  }

  private static _generatePayloadError(
    type: EAction,
    value: TPayloadType,
    errorMsg: string[],
  ): ECodepipelineAction<any> {
    return {
      type,
      payload: {
        value,
        isError: true,
        errorMsg,
      },
    };
  }

  private static _generatePayload(type: EAction, value: TPayloadType): ECodepipelineAction<any> {
    return {
      type,
      payload: {
        value,
        ...NONE_ERROR,
      },
    };
  }

  private static _verifyAndSyncFieldWithGeneralName(
    dispatch: Dispatch<ECodepipelineAction<any>>,
    value: string,
    type: EAction,
  ): boolean {
    const isError = !ValidatorUtils.isMatchRegex(value, RegexCommon.GENERAL_NAME);
    if (isError) {
      const errorMsg = [
        StringUtils.format(
          MsgCommon.MUST_BE_BETWEEN_CHARACTERS,
          DefaultCommon.INPUT_1_LENGTH,
          DefaultCommon.INPUT_100_LENGTH,
        ),
        MsgCommon.CODEPIPELINE_NAME_GENERAL,
      ];
      dispatch(ActionHandler._generatePayloadError(type, value, errorMsg));
    }
    return isError;
  }
}
